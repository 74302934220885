import { FC, useCallback, useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext, unstable_usePrompt as usePrompt } from 'react-router-dom'
import { Beforeunload } from 'react-beforeunload'
import type { FormState } from 'react-hook-form'

const CONFIRM_PROMPT = 'You have some unsaved data, do you really want to leave the page?'

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(UNSAFE_NavigationContext)

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit()
      if (result !== false) {
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}

function usePromptRHF({ message, when = true }: { message: string; when?: boolean }) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [message, when])

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message)
    return confirm
  }, [message])

  useConfirmExit(confirmExit, when)
}

interface SafeRoutingProps {
  enabled?: boolean
  formState?: FormState<any>
}

/**
 * Component to prompt confirmation before leaving form page
 * @param enabled - if true, prompt will be shown
 * @param formState - react-hook-form form state, checks if form is dirty and submit was not successful
 * @constructor
 */
const SafeRouting: FC<SafeRoutingProps> = ({ enabled, formState }) => {
  const anyDirtyFields = (formState: any) =>
    Object.keys(formState.dirtyFields).some((key) => formState.dirtyFields[key])

  usePromptRHF({
    message: CONFIRM_PROMPT,
    when: formState ? anyDirtyFields(formState) && !formState.isSubmitSuccessful : false,
  })

  usePrompt({
    when: enabled!,
    message: CONFIRM_PROMPT,
  })

  return (
    <Beforeunload
      onBeforeunload={() => {
        if (enabled) return CONFIRM_PROMPT
        return null
      }}
    />
  )
}

export default SafeRouting
