import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MuiLink from '@mui/material/Link'
import Tooltip from '../common/Tooltip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ContentCopy from '@mui/icons-material/ContentCopy'
import HelpOutline from '@mui/icons-material/HelpOutline'
import MoreVert from '@mui/icons-material/MoreVert'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Theme } from '@mui/material/styles'
import { IS_CONNECT_IT } from '../../env'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'

import {
  BackupInit,
  ExpFeatures,
  GlobalSettings,
  ImageName,
  KubernetesProvider,
  LogLevel,
  SsoConfigSource,
  OidcMetadata,
  OidcPrompt,
  Role,
  SamlMetadata,
  SsoStrategy,
  TlsCertRead,
  TlsCertWrite,
  User,
  Sso,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState, useRoutes } from '../../store'
import {
  createBackup,
  getSettings,
  saveImages,
  saveSettings,
  saveTls,
  setDevMode,
} from '../../redux/actions/settingsActions'
import { numericEnum, useConfirmationDialog, usePageParams } from '../../utils'
import { getExperimentalDevFeatures, getExperimentalExternalFeatures } from '../../utils/features'
import { ButtonsPane, Checkbox, GridItem, Paper, SafeRouting, Select, TextInput, RHF, Form } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import FileInput from './FileInput'
import { ensureOperatorToken } from '../../redux/actions/apiTokensActions'
import { useFormContext } from 'react-hook-form'
import { getSsoMetadataOidc, getSsoMetadataSaml } from '../../redux/actions/userActions'
import { FormProps } from '../common/Form/RHF'

const classes = {
  container: {
    width: '100%',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
  noMarginTop: {
    mt: 0,
    WebkitBorderTopLeftRadius: 0,
    WebkitBorderTopRightRadius: 0,
  },
  noMarginBottom: {
    mb: 0,
    WebkitBorderBottomLeftRadius: 0,
    WebkitBorderBottomRightRadius: 0,
  },
}

enum GlobalSettingsTabs {
  settings = 'settings',
  'customization' = 'customization',
  'tls certificate' = 'tls certificate',
  licenses = 'licenses',
  alarms = 'alarms',
  'auth provider' = 'auth provider',
}

const SettingsBackupsPane = () => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()
  const [anchorElConfig, setAnchorElConfig] = useState<HTMLElement | null>(null)
  const [anchorElMetrics, setAnchorElMetrics] = useState<HTMLElement | null>(null)

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    setAnchorEl: typeof setAnchorElConfig | typeof setAnchorElMetrics,
  ) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (setAnchorEl: typeof setAnchorElConfig | typeof setAnchorElMetrics) => {
    setAnchorEl(null)
  }

  const handleCreateBackup = (
    type: BackupInit['type'],
    setAnchorEl: typeof setAnchorElConfig | typeof setAnchorElMetrics,
  ) => {
    showConfirmation(() => {
      dispatch(createBackup({ type }))
      setAnchorEl(null)
    }, 'Taking backup might temporarily affect the database performance and will use additional disk space. Would you like to proceed?')
  }

  return (
    <Paper title="Backups">
      <GridItem>
        <Typography component="span">Config backups</Typography>
        <Tooltip
          title="Config backups are created every 6 hours. The retention period is 7 days."
          sx={{ marginLeft: 1 }}
        >
          <HelpOutline />
        </Tooltip>
        <Tooltip title="View config backups">
          <MuiLink href={'/backup/config'} target="_blank">
            <IconButton aria-label="View config backups">
              <OpenInNew />
            </IconButton>
          </MuiLink>
        </Tooltip>
        <Box>
          <IconButton onClick={(e) => handleOpen(e, setAnchorElConfig)} aria-label="Show actions of config backups">
            <MoreVert />
          </IconButton>
          <Menu
            open={Boolean(anchorElConfig)}
            keepMounted
            anchorEl={anchorElConfig}
            onClose={() => handleClose(setAnchorElConfig)}
          >
            <MenuItem onClick={() => handleCreateBackup('config', setAnchorElConfig)}>Take config backup</MenuItem>
            <MenuItem onClick={() => handleCreateBackup('billing', setAnchorElConfig)}>Take billing backup</MenuItem>
          </Menu>
        </Box>
      </GridItem>
      <GridItem newLine>
        <Typography component="span">Metrics backups</Typography>
        <Tooltip
          title={<>Automatic backup is currently disabled. The retention period is 7 days.</>}
          sx={{ marginLeft: 1 }}
        >
          <HelpOutline />
        </Tooltip>
        <Tooltip title={!IS_CONNECT_IT ? 'View metrics backups' : 'Not available in Connect iT'}>
          <MuiLink href={'/backup/metrics'} target="_blank">
            <IconButton aria-label="View metrics backups" disabled={IS_CONNECT_IT}>
              <OpenInNew />
            </IconButton>
          </MuiLink>
        </Tooltip>
        <Box>
          <IconButton
            disabled={IS_CONNECT_IT}
            onClick={(e) => handleOpen(e, setAnchorElMetrics)}
            aria-label="Show actions of metrics backups"
          >
            <MoreVert />
          </IconButton>
          <Menu
            open={Boolean(anchorElMetrics)}
            keepMounted
            anchorEl={anchorElMetrics}
            onClose={() => handleClose(setAnchorElMetrics)}
          >
            <MenuItem onClick={() => handleCreateBackup('metrics', setAnchorElMetrics)}>Take metrics backup</MenuItem>
          </Menu>
        </Box>
      </GridItem>
    </Paper>
  )
}

const SettingsForm = () => {
  const { formState, getValues } = useFormContext()
  const { saving, devMode } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ saving: settingsReducer.saving, devMode: settingsReducer.devMode }),
    shallowEqual,
  )
  const dispatch = useDispatch<AppDispatch>()
  const routes = useRoutes()

  return (
    <Grid container>
      <SafeRouting enabled={formState.isDirty && !formState.isSubmitSuccessful} />
      <Grid item xs={12}>
        <Form id="settings-form" noValidate>
          <Paper title="Settings" sx={classes.noMarginTop}>
            <Select
              label="Edge API log level"
              name="logLevel"
              options={Object.entries(numericEnum(LogLevel)).map(([name, value]) => ({ name, value }))}
            />
            <TextInput name="defaultDelay" label="Default delay" required type="number" noNegative />
            <Select
              label="Default broadcast standard"
              name="defaultBroadcastStandard"
              options={[
                { name: 'DVB', value: 'dvb' },
                { name: 'ATSC', value: 'atsc' },
              ]}
            />
            {!IS_CONNECT_IT && (
              <>
                <Checkbox
                  name="sslRedirectEnabled"
                  label="SSL redirection enabled"
                  tooltip={'Redirect from HTTP to HTTPS (if TLS is enabled)'}
                />
                <Checkbox
                  name="ntpEnabled"
                  label="NTP enabled"
                  disabled={getValues().kubernetesProvider !== KubernetesProvider.metal}
                />
                <TextInput name="ntpServer" label="NTP server" disabled={!getValues().ntpEnabled} />
              </>
            )}
          </Paper>
          <Paper title="Optional features">
            {getExperimentalExternalFeatures().map((feature) => (
              <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
            ))}
          </Paper>
          <SettingsBackupsPane />
          {devMode && (
            <Paper title="Experimental features">
              <GridItem>
                <Button onClick={() => dispatch(setDevMode(false))} id="disable-dev-mode-button" variant="outlined">
                  Disable dev mode
                </Button>
              </GridItem>
              <GridItem newLine>
                <Button
                  onClick={() => void dispatch(ensureOperatorToken())}
                  id="ensure-operator-token-button"
                  variant="contained"
                >
                  Ensure Edge operator token
                </Button>
              </GridItem>
              <Box sx={{ width: '100%' }} />
              {getExperimentalDevFeatures().map((feature) => (
                <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
              ))}
              <GridItem>
                <Link to={routes.status()} underline="hover">
                  Status Page
                </Link>
              </GridItem>
            </Paper>
          )}
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const TlsForm = () => {
  const { formState } = useFormContext()
  const { savingTls } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ savingTls: settingsReducer.savingTls }),
    shallowEqual,
  )

  return (
    <Grid container>
      <SafeRouting enabled={formState.isDirty && !formState.isSubmitSuccessful} />
      <Grid item xs={12}>
        <Form id="tls-form" noValidate>
          <Paper title="TLS Certificate" sx={classes.noMarginTop}>
            <TextInput name="fingerprint" label="Fingerprint" disabled />
            <TextInput name="expiresAt" label="Expires at" disabled />
            <TextInput
              name="key"
              label="Private key"
              required
              newLine
              multiline
              tooltip="Paste the RSA or ECC private key in PEM format here"
            />
            <TextInput
              name="cert"
              label="Certificate"
              required
              multiline
              tooltip="Paste the RSA or ECC certificate in PEM format here"
            />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: savingTls, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const CustomizationForm = () => {
  const { formState } = useFormContext()
  const { saving } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ saving: settingsReducer.saving }),
    shallowEqual,
  )

  return (
    <Grid container>
      <SafeRouting enabled={formState.isDirty && !formState.isSubmitSuccessful} />
      <Grid item xs={12}>
        <Paper title="Customization" sx={{ ...classes.noMarginBottom, ...classes.noMarginTop }}>
          <Grid item xs={12} lg={6}>
            <FileInput label="Login page logo" name={ImageName.product} id="login-page-logo-file-picker" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FileInput
              label="Top left logo"
              name={ImageName.serviceProvider}
              text="Best ratio is 5:3"
              id="left-corner-file-picker"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FileInput label="Favicon" name={ImageName.favicon} id="favicon-file-picker" />
          </Grid>
        </Paper>
        <Form id="customization-form" noValidate>
          <Paper sx={classes.noMarginTop}>
            <TextInput name="customTabTitle" label="Tab title" />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const LicenseForm = () => {
  const { formState } = useFormContext()
  const saving = useSelector(({ settingsReducer }: GlobalState) => settingsReducer.saving, shallowEqual)

  return (
    <Grid container>
      <SafeRouting enabled={formState.isDirty && !formState.isSubmitSuccessful} />
      <Grid item xs={12}>
        <Form id="licenses-form" noValidate>
          <Paper title="Licenses" sx={classes.noMarginTop}>
            <TextInput name="zixiFeederKey" label="Zixi feeder key" />
            <TextInput name="zixiReceiverKey" label="Zixi receiver key" />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const AlarmsForm = () => {
  const { formState } = useFormContext()
  const { saving } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ saving: settingsReducer.saving }),
    shallowEqual,
  )

  return (
    <Grid container>
      <SafeRouting enabled={formState.isDirty && !formState.isSubmitSuccessful} />
      <Grid item xs={12}>
        <Form id="alarms-form" noValidate>
          <Paper title="Alarms" sx={classes.noMarginTop}>
            <Select
              label="Nimbra 400 alarms"
              name="nimbra400Alarms"
              options={[
                { name: 'Show all', value: 'all' },
                { name: 'Show only alarms for services provisioned by Nimbra Edge', value: 'edgeManagedServices' },
                { name: 'Hide all', value: 'none' },
              ]}
            />
            <Checkbox
              name="alarmNotificationsEnabled"
              label="Show alarm notifications – Only affects new users"
              tooltip="Display alarm notifications in the UI. This setting is used as a default value for new users and can be overriden on a user basis in the User Profile page."
            />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const OidcForm = (props: FormProps<GlobalSettings>) => {
  const values = props.getValues()
  props.watch('sso.oidc.enabled')
  props.watch('sso.oidc.configSource')

  const configSource = values.sso.oidc?.configSource || SsoConfigSource.metadata
  const enabled = values.sso.oidc?.enabled || false

  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const [redirectUrl] = useState<string>(window.location.origin + '/api/sso/oidc/callback')

  const fetchMetadataFromUrl = (url: string) => {
    try {
      new URL(url)
    } catch {
      return
    }
    dispatch(getSsoMetadataOidc(url))?.then((metadata) => {
      const p = metadata.payload as OidcMetadata
      if (p) {
        props.setValue('sso.oidc.issuer', p.issuer)
        props.setValue('sso.oidc.userInfoUrl', p.userInfoUrl)
        props.setValue('sso.oidc.authorizationUrl', p.authorizationUrl)
        props.setValue('sso.oidc.tokenUrl', p.tokenUrl, { shouldValidate: true })
      }
    })
  }

  const product = IS_CONNECT_IT ? 'Connect iT' : 'Edge'
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form id="auth-provider-oidc-form" noValidate>
          <Paper sx={classes.noMarginTop}>
            <Checkbox newLine label="Enabled" name="sso.oidc.enabled" />

            <GridItem newLine sx={{ mt: 1 }}>
              <Typography variant="h2">Customization</Typography>
            </GridItem>

            <TextInput
              newLine
              name="sso.oidc.displayName"
              label="Display name"
              tooltip="The name of the provider displayed on the login button"
            />
            <TextInput
              name="sso.oidc.displayOrder"
              label="Display order"
              type="number"
              tooltip="If multiple SSO providers are enabled, this determines the order in which they are displayed on the login page"
            />

            <GridItem
              newLine
              sx={{ mt: 5 }}
              tooltip={`Map certain fields from your IdP to ${product}. Group mappings allow a user to be assigned ${product} roles based on their groups in the IdP (This applies only the first time a user logs in)`}
            >
              <Typography variant="h2" sx={{ mt: 1 }}>
                Claims and Mappings
              </Typography>
            </GridItem>
            <TextInput
              newLine
              name="sso.oidc.emailClaim"
              label="Email claim"
              placeholder="email"
              tooltip="Optional: Field in the ID token that contains the user's email address. Default: email"
            />
            <TextInput
              name="sso.oidc.groupsClaim"
              label="Groups claim"
              placeholder="groups"
              tooltip="Optional: Field in the ID token that contains the user's groups. Default: groups"
            />

            <GridItem newLine>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>OIDC Group</TableCell>
                    <TableCell>Edge Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(Role).map((role, index) => (
                    <TableRow key={index}>
                      <TableCell width="70%">
                        <TextInput
                          name={`sso.oidc.groupMappings[${role}]`}
                          hiddenLabel
                          xl={12}
                          lg={12}
                          placeholder="Group name in the identity provider"
                        />
                      </TableCell>
                      <TableCell width="30%">{role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GridItem>

            <GridItem newLine sx={{ mt: 5 }}>
              <Typography variant="h2">Settings</Typography>
            </GridItem>
            <GridItem newLine>
              <Tabs value={configSource} variant="fullWidth" sx={classes.container} textColor="inherit">
                <Tab
                  label="From Metadata"
                  value={SsoConfigSource.metadata}
                  onClick={() => {
                    props.setValue('sso.oidc.configSource', SsoConfigSource.metadata, { shouldValidate: true })
                  }}
                />
                <Tab
                  label="Enter Manually"
                  value={SsoConfigSource.manual}
                  onClick={() => {
                    props.setValue('sso.oidc.configSource', SsoConfigSource.manual, { shouldValidate: true })
                  }}
                />
              </Tabs>
            </GridItem>

            {configSource === SsoConfigSource.metadata && (
              <TextInput
                newLine
                name="sso.oidc.metadataUrl"
                label="Metadata URL"
                tooltip="The URL of the OIDC provider's configuration. URL typically ends with .well-known/openid-configuration"
                placeholder="https://example.com/.well-known/openid-configuration"
                required={enabled && configSource === SsoConfigSource.metadata}
                onBlur={(e: any) => fetchMetadataFromUrl(e.target.value)}
              />
            )}

            <TextInput newLine name="sso.oidc.clientId" label="Client ID" required={enabled} />
            <TextInput name="sso.oidc.clientSecret" label="Client secret" required={enabled} />
            <TextInput
              name="sso.oidc.scope"
              label="Scope"
              placeholder="openid email"
              tooltip="Optional: Space-separated values that define the scope of the token request. It is used to request specific claims from the OIDC provider (e.g., email, groups). Default: openid email"
            />

            <TextInput
              newLine
              name="sso.oidc.issuer"
              label="Issuer"
              required={enabled && configSource === SsoConfigSource.manual}
              disabled={configSource === SsoConfigSource.metadata}
            />
            <TextInput
              name="sso.oidc.userInfoUrl"
              label="User info URL"
              required={enabled && configSource === SsoConfigSource.manual}
              disabled={configSource === SsoConfigSource.metadata}
            />
            <TextInput
              name="sso.oidc.authorizationUrl"
              label="Authorization URL"
              required={enabled && configSource === SsoConfigSource.manual}
              disabled={configSource === SsoConfigSource.metadata}
            />
            <TextInput
              name="sso.oidc.tokenUrl"
              label="Token URL"
              required={enabled && configSource === SsoConfigSource.manual}
              disabled={configSource === SsoConfigSource.metadata}
            />

            <GridItem newLine></GridItem>
            <GridItem newLine></GridItem>
            <GridItem newLine>
              <TextField fullWidth label="Callback URL" value={redirectUrl} type="text" disabled={true} />
              {navigator.clipboard && (
                <IconButton sx={{ marginLeft: '3px' }} onClick={() => navigator.clipboard.writeText(redirectUrl)}>
                  <ContentCopy />
                </IconButton>
              )}
            </GridItem>

            <GridItem newLine sx={{ mt: 5 }}>
              <Typography variant="h2">Advanced Settings</Typography>
            </GridItem>
            <TextInput
              newLine
              name="sso.oidc.acrValues"
              label="ACR values"
              tooltip="Specifies the required authentication level or method for the user. Use this to request a stronger authentication method, such as multi-factor authentication (MFA), from the identity provider."
            />
            <Select
              name="sso.oidc.prompt"
              label="Prompt"
              options={[
                { name: 'None', value: OidcPrompt.none },
                { name: 'Login', value: OidcPrompt.login },
                { name: 'Select account', value: OidcPrompt.select_account },
              ]}
              tooltip='Specifies whether the identity provider should prompt the user for re-authentication. "None" will not prompt the user, "Login" will prompt the user to login, and "Select account" will prompt the user to select an account.'
            />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const SamlForm = (props: FormProps<GlobalSettings>) => {
  const values = props.getValues()
  const enabled = values.sso.saml?.enabled || false
  const configSource = values.sso.saml?.configSource || SsoConfigSource.metadata

  props.watch('sso.saml.enabled')
  props.watch('sso.saml.configSource')
  props.watch('sso.saml.signedAuthnRequests')
  props.watch('sso.saml.signedAssertions')

  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const [redirectUrl] = useState<string>(window.location.origin + '/api/sso/saml/callback')

  const dispatch = useDispatch<AppDispatch>()

  const fetchMetadataFromUrl = (url: string) => {
    try {
      new URL(url)
    } catch {
      return
    }
    dispatch(getSsoMetadataSaml(url))?.then((metadata) => {
      const p = metadata.payload as SamlMetadata
      if (p) {
        props.setValue('sso.saml.loginUrl', p.loginUrl)
        props.setValue('sso.saml.certificate', p.certificate, { shouldValidate: true })
      }
    })
  }

  const product = IS_CONNECT_IT ? 'Connect iT' : 'Edge'
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form id="auth-provider-saml-form" noValidate>
          <Paper sx={classes.noMarginTop}>
            <Checkbox label="Enabled" name="sso.saml.enabled" />
            <GridItem newLine sx={{ mt: 1 }}>
              <Typography variant="h2">Customization</Typography>
            </GridItem>
            <TextInput
              newLine
              name="sso.saml.displayName"
              label="Display name"
              tooltip="The name of the provider displayed on the login button"
            />
            <TextInput
              name="sso.saml.displayOrder"
              label="Display order"
              type="number"
              tooltip="If multiple SSO providers are enabled, this determines the order in which they are displayed on the login page"
            />
            <GridItem
              newLine
              tooltip={`Map certain fields from your IdP to ${product}. Group mappings allow a user to be assigned ${product} roles based on their groups in the IdP (This applies only the first time a user logs in)`}
              sx={{ mt: 5 }}
            >
              <Typography variant="h2" sx={{ mt: 1 }}>
                Claims and Mappings
              </Typography>
            </GridItem>
            <TextInput
              newLine
              name="sso.saml.emailClaim"
              label="Email claim"
              placeholder="email"
              tooltip="Optional: Field in the SAML response that contains the user's email address. Default: email"
            />
            <TextInput
              name="sso.saml.groupsClaim"
              label="Groups claim"
              placeholder="groups"
              tooltip="Optional: Field in the SAML response that contains the user's groups. Default: groups"
            />

            <GridItem newLine>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SAML Group</TableCell>
                    <TableCell>Edge Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(Role).map((role, index) => (
                    <TableRow key={index}>
                      <TableCell width="70%">
                        <TextInput
                          name={`sso.saml.groupMappings[${role}]`}
                          hiddenLabel
                          xl={12}
                          lg={12}
                          placeholder="Group name in the identity provider"
                        />
                      </TableCell>
                      <TableCell width="30%">{role}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GridItem>

            <GridItem newLine sx={{ mt: 5 }}>
              <Typography variant="h2">Settings</Typography>
            </GridItem>
            <GridItem newLine>
              <Tabs value={configSource} variant="fullWidth" sx={classes.container} textColor="inherit">
                <Tab
                  label="From Metadata"
                  value={SsoConfigSource.metadata}
                  onClick={() => {
                    props.setValue('sso.saml.configSource', SsoConfigSource.metadata, { shouldValidate: true })
                  }}
                />
                <Tab
                  label="Enter Manually"
                  value={SsoConfigSource.manual}
                  onClick={() => {
                    props.setValue('sso.saml.configSource', SsoConfigSource.manual, { shouldValidate: true })
                  }}
                />
              </Tabs>
            </GridItem>
            {configSource === SsoConfigSource.metadata && (
              <TextInput
                newLine
                name="sso.saml.metadataUrl"
                label="Metadata URL"
                tooltip="The URL of the SAML provider's configuration. Depending on your auth provider, this URL might not be publicly accessible. If you experience issues when using this field, please use the manual configuration option instead."
                placeholder="https://example.com/saml-metadata.xml"
                required={enabled && configSource === SsoConfigSource.metadata}
                onBlur={(e: any) => fetchMetadataFromUrl(e.target.value)}
              />
            )}
            <TextInput
              name="sso.saml.providerId"
              label="Provider ID"
              required={enabled}
              tooltip="The unique identifier of the SAML provider"
              newLine
            />
            <TextInput
              name="sso.saml.loginUrl"
              label="Login URL"
              required={enabled && configSource === SsoConfigSource.manual}
              disabled={configSource === SsoConfigSource.metadata}
            />
            <TextInput
              label="Certificate"
              name="sso.saml.certificate"
              multiline
              rows={5}
              variant="outlined"
              placeholder="Paste the SAML certificate in PEM format here"
              required={enabled && configSource === SsoConfigSource.manual}
              disabled={configSource === SsoConfigSource.metadata}
            />
            <GridItem newLine>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <TextField fullWidth label="Callback URL" value={redirectUrl} type="text" disabled={true} />
                <IconButton sx={{ marginLeft: '3px' }} onClick={() => navigator.clipboard.writeText(redirectUrl)}>
                  <ContentCopy />
                </IconButton>
              </Box>
            </GridItem>
            <GridItem newLine sx={{ mt: 5 }}>
              <Typography variant="h2">Advanced Settings</Typography>
            </GridItem>
            <Checkbox
              newLine
              label="Force authentication"
              tooltip="Force the user to re-authenticate with the IDP on every login."
              name="sso.saml.forceAuthentication"
            />
            <TextInput
              name="sso.saml.acceptedClockSkewMs"
              label="Accepted clock skew (ms)"
              type="number"
              tooltip="Time in milliseconds of skew that is acceptable between client and server when checking `NotOnOrAfter` assertion condition validity timestamp. Setting to `-1` will disable checking entirely. Default: 0"
            />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const AuthProviderForm = (props: FormProps<GlobalSettings>) => {
  const values = props.getValues()

  const [ssoTab, setSsoTab] = useState<string>(() => {
    if (values.sso.oidc?.enabled && !values.sso.saml?.enabled) return SsoStrategy.oidc
    if (!values.sso.oidc?.enabled && values.sso.saml?.enabled) return SsoStrategy.saml
    return SsoStrategy.saml
  })

  return (
    <>
      <Paper sx={{ ...classes.noMarginBottom, ...classes.noMarginTop }}>
        <GridItem>
          <Tabs value={ssoTab} variant="fullWidth" sx={classes.container} textColor="inherit">
            <Tab label="SAML" value={SsoStrategy.saml} onClick={() => setSsoTab(SsoStrategy.saml)} />
            <Tab label="OIDC" value={SsoStrategy.oidc} onClick={() => setSsoTab(SsoStrategy.oidc)} />
          </Tabs>
        </GridItem>
      </Paper>

      {ssoTab === SsoStrategy.saml && <SamlForm {...props} />}
      {ssoTab === SsoStrategy.oidc && <OidcForm {...props} />}
    </>
  )
}

const defaultExpFeatures = Object.keys(ExpFeatures).reduce((a, key) => {
  return { ...a, [key]: false }
}, {})

export const Settings = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [{ settingsTab = GlobalSettingsTabs.settings }, setPageParams] = usePageParams()
  useEffect(() => {
    dispatch(getSettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { settings, user, tls } = useSelector(
    ({ settingsReducer, userReducer }: GlobalState) => ({
      settings: settingsReducer.settings,
      tls: settingsReducer.tls,
      user: userReducer.user as User,
    }),
    shallowEqual,
  )

  const handleSubmit = async (values: any) => {
    dispatch(saveSettings(values))
  }
  const handleTlsSubmit = async (values: any) => {
    dispatch(saveTls(values))
  }

  if (user && user.role !== Role.super) return null

  const edgeTabs = Object.values(GlobalSettingsTabs)
  const lociDisallowedTabs = [GlobalSettingsTabs['customization'], GlobalSettingsTabs.licenses]
  const lociTabs = edgeTabs.filter((v) => !lociDisallowedTabs.includes(v))
  const globalSettingsTabs = !IS_CONNECT_IT ? edgeTabs : lociTabs

  return (
    <Wrapper name="Global settings">
      <Paper sx={{ ...classes.noMarginBottom, paddingBottom: 0 }}>
        <Tabs
          value={settingsTab}
          variant="fullWidth"
          onChange={(_, val) => {
            if (val === 'doc') return
            setPageParams({ settingsTab: val })
          }}
          sx={classes.container}
          textColor="inherit"
        >
          {globalSettingsTabs.map((v) => (
            <Tab key={`tab-${v}`} label={v} value={v} />
          ))}
          <Tab
            label="Documentation"
            value="doc"
            component="a"
            href="/docs/"
            target="_blank"
            icon={<OpenInNew />}
            iconPosition="end"
          />
        </Tabs>
      </Paper>
      <Pendable pending={!settings}>
        {settingsTab === GlobalSettingsTabs.settings && (
          <RHF
            onSubmit={handleSubmit}
            defaultValues={
              {
                ...settings,
                expFeatures: Object.assign({}, defaultExpFeatures, settings?.expFeatures),
              } as GlobalSettings
            }
            enableReinitialize
            component={SettingsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['tls certificate'] && (
          <RHF
            onSubmit={handleTlsSubmit}
            enableReinitialize
            defaultValues={{ ...tls, cert: '', key: '' } as TlsCertWrite & TlsCertRead}
            component={TlsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['customization'] && (
          <RHF
            onSubmit={async (values) => {
              dispatch(saveImages({ snackbar: false }))
              await handleSubmit(values)
            }}
            defaultValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={CustomizationForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs.licenses && (
          <RHF
            onSubmit={handleSubmit}
            defaultValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={LicenseForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs.alarms && (
          <RHF
            onSubmit={handleSubmit}
            defaultValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={AlarmsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['auth provider'] && (
          <RHF
            onSubmit={handleSubmit}
            defaultValues={(() => {
              // Since SSO fields are all optional, we need to make sure they are initialized to ensure a Controlled component
              const defaultValues: Sso = {
                oidc: {
                  enabled: false,
                  displayName: '',
                  displayOrder: undefined,
                  emailClaim: '',
                  groupsClaim: '',
                  groupMappings: {},
                  configSource: SsoConfigSource.metadata,
                  metadataUrl: '',
                  clientId: '',
                  clientSecret: '',
                  scope: '',
                  issuer: '',
                  userInfoUrl: '',
                  authorizationUrl: '',
                  tokenUrl: '',
                  acrValues: '',
                  prompt: OidcPrompt.login,
                },
                saml: {
                  enabled: false,
                  displayName: '',
                  displayOrder: undefined,
                  emailClaim: '',
                  groupsClaim: '',
                  groupMappings: {},
                  configSource: SsoConfigSource.metadata,
                  metadataUrl: '',
                  providerId: '',
                  loginUrl: '',
                  certificate: '',
                  forceAuthentication: false,
                  signedAuthnRequests: false,
                  signedAssertions: false,
                  publicCertificate: '',
                  privateKey: '',
                  signatureAlgorithm: '',
                  digestAlgorithm: '',
                  acceptedClockSkewMs: 0,
                },
              }

              const newSettings = { ...settings, sso: { ...settings?.sso } } // Create a shallow copy of settings and sso
              newSettings.sso.oidc = { ...defaultValues.oidc, ...settings?.sso?.oidc } // Merge oidc settings with defaults
              newSettings.sso.saml = { ...defaultValues.saml, ...settings?.sso?.saml } // Merge saml settings with defaults

              return {
                ...newSettings,
              } as GlobalSettings
            })()}
            component={AuthProviderForm}
          />
        )}
      </Pendable>
    </Wrapper>
  )
}
