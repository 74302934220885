import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ButtonProps } from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { styles as formStyles } from './styles'
import LoadingButton from '@mui/lab/LoadingButton'

const getStyle = (primary?: boolean): { color: ButtonProps['color']; variant: ButtonProps['variant'] } =>
  primary ? { color: 'primary', variant: 'contained' } : { color: 'secondary', variant: 'outlined' }

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'flex-end',
  },
}

interface ButtonCompProps {
  text: string
  savingState?: boolean
  onClick?: () => void
  primary?: boolean
  id?: string
  type?: 'submit'
  disabled?: boolean
  tooltip?: string
}
export interface ButtonsPaneProps {
  main: { [key: string]: Omit<ButtonCompProps, 'text'> }
  secondary?: { [key: string]: Omit<ButtonCompProps, 'text'> }
}
/**
 * Button for common paper's button pane
 * @param text - button text
 * @param onClick - function to call
 * @param savingState - if true shows spinner instead
 * @param primary - true for main button (mostly 'save')
 * @param id
 */
const ButtonComp = ({ text, onClick, savingState, primary, id, type, disabled, tooltip }: ButtonCompProps) => {
  const getButton = () => {
    const button = (
      <LoadingButton
        loading={!!savingState}
        {...getStyle(primary)}
        onClick={onClick}
        id={id || `button-${text.split(' ').join('-').toLowerCase()}`}
        type={type}
        disabled={disabled}
      >
        {text}
      </LoadingButton>
    )

    // Add tooltip if provided
    // Button need to be wrapped in a Box for tooltip to work if button is disabled
    if (tooltip !== undefined) {
      return (
        <Tooltip title={tooltip} placement="top">
          <Box>{button}</Box>
        </Tooltip>
      )
    }

    return button
  }

  return <Box sx={formStyles.button}>{getButton()}</Box>
}

/**
 * Common Buttons pane on the bottom of the form
 * @param main - set of main buttons on the right side
 * @param secondary - set of optional buttons on the left side
 */
const ButtonsPane = ({ main, secondary }: ButtonsPaneProps) => {
  return (
    <Grid container>
      {!!secondary && (
        <Grid item xs={12} sm={6} container>
          {Object.entries(secondary).map(([text, button]) => (
            <ButtonComp key={text} {...button} text={text} />
          ))}
        </Grid>
      )}
      <Grid item xs={12} sm={secondary ? 6 : 12} container sx={styles.buttonContainer}>
        {Object.entries(main).map(([text, button]) => (
          <ButtonComp key={text} {...button} text={text} />
        ))}
      </Grid>
    </Grid>
  )
}

export default ButtonsPane
