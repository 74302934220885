import { ApiToken, ApiTokenInit, ListResult } from 'common/api/v1/types'
import { EdgeClient } from 'common/generated/edgeClient'

export interface IApiTokenApi {
  ensureOperatorToken(): Promise<void>
  createApiToken(apiToken: ApiTokenInit): Promise<ApiToken>
  removeApiToken(id: ApiToken['id']): Promise<void>
  getApiTokens(): Promise<ListResult<ApiToken>>
}

export class ApiTokenApi implements IApiTokenApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  /**
   * Creates and ensures a super user API token to be used by the Edge Operator. This needs to be done before installing the Operator in an existing cluster.
   */
  ensureOperatorToken(): Promise<void> {
    return this.edgeClient.setApiTokenEnsureOperatorToken()
  }

  createApiToken(apiToken: ApiTokenInit) {
    return this.edgeClient.createApiToken(apiToken)
  }

  async removeApiToken(id: ApiToken['id']) {
    await this.edgeClient.deleteApiToken(id)
  }

  getApiTokens() {
    return this.edgeClient.listApiTokens()
  }
}
