import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles'

import { Output, OutputRedundancyMode } from 'common/api/v1/types'
import { Paper, Select } from '../../common/Form'
import Thumbnail from '../../common/Thumbnail'
import DataSet from '../../common/DataSet'
import { Link } from '../../common/Link'
import { SelectInputDialog } from '../SelectInputDialog'
import { isEditableGroup, useConfirmationDialog, useUser } from '../../../utils'
import { EnrichedOutputWithEnrichedPorts } from './index'
import { EnrichedInput } from '../../../api/nm-types'
import { getFormattedTransportStreamContent } from 'common/api/v1/helpers'
import { isCoreNode } from '../../common/Interface/Base'
import { inputRedundancy } from 'common/utils'

import { useRoutes } from '../../../store'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from '../../common/Form/RHF'

const styles = {
  container: {
    display: 'flex',
  },
  thumb: {
    width: (theme: Theme) => theme.spacing(40),
    marginRight: (theme: Theme) => theme.spacing(2),
  },
}

const InputPicker = () => {
  const { setValue, getValues } = useFormContext<EnrichedOutputWithEnrichedPorts & AllowUncontrolled>()

  const [showSelectInputDialog, setShowSelectInputDialog] = useState(false)
  const user = useUser()
  const routes = useRoutes()
  const setConfirm = useConfirmationDialog()

  const values = getValues()

  const onInputSelect = (output: Output, input: EnrichedInput) => {
    const didSelectDifferentInput = values.input !== input.id
    if (!didSelectDifferentInput) {
      return
    }
    const isRedundantOutput = output.redundancyMode != OutputRedundancyMode.none
    const outputRedundancyNotPossible = !inputRedundancy(input)
    const action = () => {
      setValue('input', input.id)
      setValue('_input', input)
    }
    if (isRedundantOutput && outputRedundancyNotPossible) {
      const callback = () => {
        setValue('redundancyMode', OutputRedundancyMode.none)
        action()
      }
      setConfirm(callback, 'You have selected a non-redundant input - output redundancy will be disabled. Proceed?')
    } else {
      action()
    }
  }
  const onClearInput = () => {
    setValue('input', undefined)
    setValue('_input', undefined)
  }
  const input = getValues('_input') as EnrichedInput | undefined
  const inputIsRedundant = !!input && inputRedundancy(input)

  const format = getFormattedTransportStreamContent((input?.tsInfo || [])[0])

  return (
    <Paper
      className="outlined"
      title="Input"
      collapsible
      actionsPane={[
        <Button
          key="select-input"
          id="select-input"
          variant="contained"
          color="secondary"
          onClick={() => setShowSelectInputDialog(true)}
        >
          Switch input
        </Button>,
      ]}
    >
      <Grid item xs={12}>
        {!!values._input && (
          <Box sx={styles.container}>
            <Box sx={styles.thumb}>
              <Thumbnail input={values._input} />
            </Box>
            <DataSet
              values={{
                Name: (
                  <Link
                    to={routes.inputsUpdate({ id: values._input?.id })}
                    underline="hover"
                    available={!!values._input?.canSubscribe}
                  >
                    <span data-test-id={'selected-input-name'}>{values._input.name}</span>
                  </Link>
                ),
                Owner: (
                  <Link
                    to={routes.groupsUpdate({ id: values._input?._owner?.id })}
                    underline="hover"
                    available={!!values._input?._owner?.id && isEditableGroup(values._input?._owner?.id, user)}
                  >
                    {values._input?._owner?.name}
                  </Link>
                ),
                Format: format,
              }}
            />
          </Box>
        )}

        {inputIsRedundant && !isCoreNode(values) && (
          <Select
            tooltip="Failover: a secondary redundant path will be activated if the primary one becomes unhealthy."
            name={'redundancyMode'}
            label="Redundancy mode"
            required
            options={Object.entries(OutputRedundancyMode)
              .filter(([, value]) => typeof value === 'number')
              .map(([name, value]) => ({ name, value }))}
            newLine
          />
        )}
      </Grid>
      <SelectInputDialog
        show={showSelectInputDialog}
        output={values as EnrichedOutputWithEnrichedPorts}
        closeDialog={() => setShowSelectInputDialog(false)}
        onInputSelect={onInputSelect}
        onClearInput={onClearInput}
      />
    </Paper>
  )
}

export default InputPicker
