import TextField from '@mui/material/TextField'
import { AudioReshufflingMapping, AudioReshufflingPassthrough, AudioReshufflingStream } from 'common/api/v1/types'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { default as MuiPaper } from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTheme, darken, lighten } from '@mui/material/styles'
import { Paper, Select } from '../../common/Form'
import Chip from '@mui/material/Chip'
import { InputFormProps } from '../Edit/InputForm'
import Divider from '@mui/material/Divider'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { AppDispatch } from '../../../store'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from '../../../redux/actions/notificationActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { distinct, group } from 'common/utils'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import CableIcon from '@mui/icons-material/Cable'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

export const AudioReshuffling = () => {
  const EMPTY_PID = 0
  const [inputs, setInputs] = useState<AudioReshufflingStream[]>([])
  const [inputPids, setInputPids] = useState<number[]>([])
  const [outputs, setOutputs] = useState<AudioReshufflingStream[]>([])
  const [outputPids, setOutputPids] = useState<number[]>([])
  const [mappings, setMappings] = useState<AudioReshufflingMapping[]>([])
  const [passthrough, _setPassthrough] = useState<AudioReshufflingPassthrough[]>([])
  const [inputPassthroughPid, setInputPassthroughPid] = useState<string>('')
  const [outputPassthroughPid, setOutputPassthroughPid] = useState<string>('')
  const [arePidErrors, setArePidErrors] = useState(true)

  const setPassthrough = useCallback(
    (passthrough: AudioReshufflingPassthrough[]) => {
      _setPassthrough(
        distinct(
          passthrough.toSorted((a, b) => {
            if (a.inputPid === b.inputPid) return a.outputPid - b.outputPid
            return a.inputPid - b.inputPid
          }),
          function areEqual(a, b) {
            return a.inputPid === b.inputPid && a.outputPid === b.outputPid
          },
        ),
      )
    },
    [_setPassthrough],
  )

  const { getValues, setValue } = useFormContext<InputFormProps & AllowUncontrolled>()
  const values = getValues()
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const channelNumberingOptions = ['Relative', 'Absolute']
  const sortMappingsByOptions = ['Input', 'Output']
  const [selectedChannelNumbering, setSelectedChannelNumbering] = useState(channelNumberingOptions[0])
  const [selectedSortMappingsBy, setSelectedSortMappingsBy] = useState(sortMappingsByOptions[0])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setArePidErrors(outputPids.includes(EMPTY_PID) || inputPids.includes(EMPTY_PID))
  }, [outputPids, inputPids])

  useEffect(() => {
    if (loading) return
    if (!(values?.deriveFrom?.ingestTransform?.type === 'audio-reshuffling')) return

    values.deriveFrom.ingestTransform.ffmpegParams = {
      ...values.deriveFrom.ingestTransform.ffmpegParams,
      audioReshuffling: {
        inputs,
        outputs,
        mappings,
        passthrough,
      },
    }
  }, [inputs, outputs, mappings, passthrough])

  // Apply initial state, when there are no params
  useEffect(() => {
    if (!loading) return
    if (!(values?.deriveFrom?.ingestTransform?.type === 'audio-reshuffling')) return

    if (!values.deriveFrom.ingestTransform.ffmpegParams?.audioReshuffling) {
      setValue(
        'deriveFrom.ingestTransform.ffmpegParams',
        {
          type: 'audio-reshuffling',
          audioReshuffling: {
            inputs: [{ pid: EMPTY_PID, channels: 2 }],
            outputs: [{ pid: EMPTY_PID, channels: 2 }],
            mappings: [],
            passthrough: [],
          },
          audioCodec: 'aac',
        },
        { shouldValidate: true },
      )
      return
    }

    const { inputs, outputs, mappings, passthrough } = values.deriveFrom.ingestTransform.ffmpegParams.audioReshuffling
    setInputs(inputs)
    setOutputs(outputs)
    setMappings(mappings)
    setPassthrough(passthrough)
    setLoading(false)
    setInputPids(inputs.map((i) => i.pid))
    setOutputPids(outputs.map((i) => i.pid))
  })

  const pidHelperText = (pid: number, passthrough = false) => {
    if ((pid === EMPTY_PID || !pid) && !passthrough) return 'Required'
    if (pid < 32) return 'Min 32'
    if (pid > 8191) return 'Max 8190'
    if (pid === 8187) return 'Reserved'
  }

  const cellBackground = (outputIndex: number, inputIndex: number) => {
    const baseColor = darken(theme.palette.grey[900], outputIndex % 2 === 0 ? 0.1 : 0)

    if (inputIndex % 2 === 0)
      return {
        backgroundColor: baseColor,
      }

    return {
      background: `repeating-linear-gradient(
      ${baseColor},
      ${baseColor} 2.5px,
      ${lighten(theme.palette.grey[900], 0.05)} 2.5px,
      ${lighten(theme.palette.grey[900], 0.05)} 5px
    );`,
    }
  }

  const handleAddPassthrough = () => {
    if (isNaN(parseInt(inputPassthroughPid)) || isNaN(parseInt(outputPassthroughPid))) return

    setPassthrough([
      ...passthrough,
      { inputPid: parseInt(inputPassthroughPid), outputPid: parseInt(outputPassthroughPid) },
    ])
    setInputPassthroughPid('')
    setOutputPassthroughPid('')
  }

  const Mappings = () => {
    if (mappings.length === 0) return null

    const rows = group(
      mappings.sort((a, b) => {
        if (selectedSortMappingsBy === 'Input') {
          return a.input.pid === b.input.pid ? a.input.channel - b.input.channel : a.input.pid - b.input.pid
        }
        return a.output.pid === b.output.pid ? a.output.channel - b.output.channel : a.output.pid - b.output.pid
      }),
      (m) => `${m.input.pid}-${m.input.channel}`,
    )

    return (
      <>
        <Stack direction="column" alignItems="flex-start" spacing={2}>
          <Typography variant="h5">Mappings</Typography>

          {Object.entries(rows).map(([row, rowMappings]) => (
            <Stack direction="row" spacing={2} flexWrap="wrap" alignItems="center" useFlexGap key={row}>
              {rowMappings.map((mapping, index) => (
                <Chip
                  key={`${row}-${index}`}
                  icon={<ShuffleIcon />}
                  sx={{ fontWeight: 'bolder' }}
                  variant="outlined"
                  label={`${mapping.input.pid} ch ${mapping.input.channel} → ${mapping.output.pid} ch ${mapping.output.channel}`}
                  onDelete={() => {
                    setMappings(
                      mappings.filter(
                        (m) =>
                          m.input.pid !== mapping.input.pid ||
                          m.input.channel !== mapping.input.channel ||
                          m.output.pid !== mapping.output.pid ||
                          m.output.channel !== mapping.output.channel,
                      ),
                    )
                  }}
                />
              ))}
            </Stack>
          ))}
        </Stack>

        <Divider />
      </>
    )
  }

  const ViewOptions = () => {
    return (
      <Stack direction="column" spacing={2}>
        <Typography variant="h5">View options</Typography>
        <Stack direction="row" spacing={2} flexWrap={'wrap'} useFlexGap>
          <Stack direction="column" spacing={1}>
            <Typography>Sort mappings by</Typography>
            <RadioGroup row value={selectedSortMappingsBy} onChange={(e) => setSelectedSortMappingsBy(e.target.value)}>
              {sortMappingsByOptions.map((option, index) => (
                <FormControlLabel value={option} control={<Radio />} label={option} key={index} />
              ))}
            </RadioGroup>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography>Channel numbering</Typography>
            <RadioGroup
              row
              value={selectedChannelNumbering}
              onChange={(e) => setSelectedChannelNumbering(e.target.value)}
            >
              {channelNumberingOptions.map((option, index) => (
                <FormControlLabel value={option} control={<Radio />} label={option} key={index} />
              ))}
            </RadioGroup>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  const getChannelNumber = (list: 'inputs' | 'outputs', index: number, channelId: number) => {
    if (selectedChannelNumbering === 'Relative') {
      return channelId
    }

    let number = 0

    for (const [i, input] of (list === 'inputs' ? inputs : outputs).entries()) {
      if (i < index) {
        number += input.channels
      } else {
        return number + channelId
      }
    }

    return number
  }

  const isCheckboxChecked = (
    input: AudioReshufflingStream,
    inputChannel: number,
    output: AudioReshufflingStream,
    outputChannel: number,
  ) => {
    return mappings.find(
      (mapping) =>
        mapping.input.pid === input.pid &&
        mapping.input.channel === inputChannel &&
        mapping.output.pid === output.pid &&
        mapping.output.channel === outputChannel,
    )
      ? true
      : false
  }

  const checkboxOnChange = (
    input: AudioReshufflingStream,
    inputChannel: number,
    output: AudioReshufflingStream,
    outputChannel: number,
    checked: boolean,
  ) => {
    if (!checked) {
      setMappings(
        mappings.filter(
          (mapping) =>
            !(
              mapping.input.pid === input.pid &&
              mapping.input.channel === inputChannel &&
              mapping.output.pid === output.pid &&
              mapping.output.channel === outputChannel
            ),
        ),
      )
      return
    }

    setMappings([
      ...mappings,
      {
        input: { pid: input.pid, channel: inputChannel },
        output: { pid: output.pid, channel: outputChannel },
      },
    ])
  }

  const checkboxRow = (input: AudioReshufflingStream, inputIndex: number, inputChannelId: number) => {
    return (
      <>
        {outputs.map((output, outputIndex) => (
          <Fragment key={outputIndex}>
            {Array.from({ length: output.channels }, (_, outputChannelIndex) => (
              <TableCell key={outputChannelIndex} sx={{ ...cellBackground(outputIndex, inputIndex) }}>
                <>
                  <Tooltip
                    title={
                      arePidErrors
                        ? 'Please enter PIDs'
                        : `Input ${input.pid} channel ${inputChannelId} to Output ${output.pid} channel ${
                            outputChannelIndex + 1
                          }`
                    }
                    disableInteractive
                  >
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                      {outputIndex === 0 && outputChannelIndex === 0 && (
                        <Typography fontFamily={'monospace'}>
                          {getChannelNumber('inputs', inputIndex, inputChannelId)}
                        </Typography>
                      )}
                      <Checkbox
                        checked={isCheckboxChecked(input, inputChannelId, output, outputChannelIndex + 1)}
                        onChange={(e) =>
                          checkboxOnChange(input, inputChannelId, output, outputChannelIndex + 1, e.target.checked)
                        }
                        disabled={arePidErrors}
                      />
                    </Stack>
                  </Tooltip>
                </>
              </TableCell>
            ))}

            {output.channels === 0 && <TableCell />}
          </Fragment>
        ))}
      </>
    )
  }

  const handleChangeOutputPid = (index: number, oldPid: number, newPid: number) => {
    setOutputPids(outputPids.map((i, iIndex) => (iIndex === index ? newPid : i)))

    if (isNaN(newPid)) return

    if (outputs.find((out, i) => out.pid === newPid && index !== i)) {
      dispatch(
        enqueueSnackbar({
          message: `PID ${newPid} already exists`,
          type: 'basic',
          options: { variant: 'error' },
        }),
      )

      return
    }

    setOutputs(outputs.map((i) => (i.pid === oldPid ? { ...i, pid: newPid } : i)))

    setMappings(
      mappings.map((m) => {
        if (m.output.pid === oldPid) {
          return { ...m, output: { ...m.output, pid: newPid } }
        }
        return m
      }),
    )
  }

  const handleChangeInputPid = (index: number, oldPid: number, newPid: number) => {
    setInputPids(inputPids.map((i, iIndex) => (iIndex === index ? newPid : i)))

    if (isNaN(newPid)) return

    if (inputs.find((inp, i) => inp.pid === newPid && index !== i)) {
      dispatch(
        enqueueSnackbar({
          message: `PID ${newPid} already exists`,
          type: 'basic',
          options: { variant: 'error' },
        }),
      )

      return
    }

    setInputs(inputs.map((i) => (i.pid === oldPid ? { ...i, pid: newPid } : i)))

    setMappings(
      mappings.map((m) => {
        if (m.input.pid === oldPid) {
          return { ...m, input: { ...m.input, pid: newPid } }
        }
        return m
      }),
    )
  }

  return (
    <>
      <Paper title={loading && 'Loading'}>
        <TableContainer component={MuiPaper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ borderBottom: 0 }}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <Typography>Outputs</Typography>
                    <ArrowRightIcon />
                  </Stack>
                </TableCell>
                {outputs.map((output, index) => (
                  <TableCell
                    key={index}
                    colSpan={output.channels || 1}
                    sx={{ ...cellBackground(index, 0), borderBottom: 0 }}
                  >
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                      <TextField
                        type="number"
                        label={`PID ${index + 1}`}
                        size="small"
                        sx={{ width: 100 }}
                        value={outputPids[index] === EMPTY_PID ? '' : outputPids[index]}
                        onChange={(e) => handleChangeOutputPid(index, output.pid, parseInt(e.target.value))}
                        error={pidHelperText(outputPids[index]) !== undefined}
                        helperText={pidHelperText(outputPids[index])}
                      />
                      <TextField
                        type="number"
                        label="No. channels"
                        size="small"
                        sx={{ width: 100 }}
                        value={output.channels}
                        onChange={(e) =>
                          setOutputs(
                            outputs.map((i, iIndex) =>
                              iIndex === index ? { ...i, channels: Math.min(parseInt(e.target.value), 48) } : i,
                            ),
                          )
                        }
                      />
                    </Stack>
                  </TableCell>
                ))}
                <TableCell align="right" sx={{ borderBottom: 0 }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOutputs([...outputs, { pid: outputs[outputs.length - 1]?.pid + 1 || 32, channels: 2 }])
                      setOutputPids([...outputPids, outputs[outputs.length - 1]?.pid + 1 || 32])
                    }}
                    sx={{ whiteSpace: 'nowrap' }}
                    disabled={arePidErrors}
                  >
                    Add output
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Stack direction="row" alignItems="center">
                    <Typography>Inputs</Typography>
                    <ArrowDropDownIcon />
                  </Stack>
                </TableCell>
                {outputs.map((output, outputIndex) => (
                  <Fragment key={outputIndex}>
                    {Array.from({ length: output.channels }, (_, outputChannelId) => (
                      <TableCell key={outputChannelId} sx={{ ...cellBackground(outputIndex, 0) }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                          <Typography fontFamily={'monospace'}>
                            {getChannelNumber('outputs', outputIndex, outputChannelId) + 1}
                          </Typography>
                        </Stack>
                      </TableCell>
                    ))}
                  </Fragment>
                ))}
                <TableCell />
              </TableRow>

              {inputs.map((input, inputIndex) => (
                <Fragment key={inputIndex}>
                  <TableRow>
                    <TableCell rowSpan={input.channels || 1} sx={{ ...cellBackground(1, inputIndex) }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          type="number"
                          label={`PID ${inputIndex + 1}`}
                          size="small"
                          sx={{ width: 100, background: theme.palette.grey[900] }}
                          value={inputPids[inputIndex] === EMPTY_PID ? '' : inputPids[inputIndex]}
                          onChange={(e) => handleChangeInputPid(inputIndex, input.pid, parseInt(e.target.value))}
                          error={pidHelperText(inputPids[inputIndex]) !== undefined}
                          helperText={pidHelperText(inputPids[inputIndex])}
                        />
                        <TextField
                          type="number"
                          label="No. channels"
                          size="small"
                          sx={{ width: 100, background: theme.palette.grey[900] }}
                          value={input.channels}
                          onChange={(e) =>
                            setInputs(
                              inputs.map((i, iIndex) =>
                                iIndex === inputIndex ? { ...i, channels: Math.min(parseInt(e.target.value), 48) } : i,
                              ),
                            )
                          }
                        />
                      </Stack>
                    </TableCell>

                    {input.channels === 0
                      ? outputs.map((output, outputIndex) => (
                          <TableCell
                            key={outputIndex}
                            colSpan={output.channels}
                            sx={{ ...cellBackground(outputIndex, inputIndex) }}
                          />
                        ))
                      : checkboxRow(input, inputIndex, 1)}

                    <TableCell align="right" rowSpan={input.channels || 1} sx={{ ...cellBackground(1, inputIndex) }}>
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => {
                          setInputs(inputs.filter((_, i) => i !== inputIndex))
                          setInputPids(inputPids.filter((_, i) => i !== inputIndex))
                          setMappings(mappings.filter((m) => m.input.pid !== input.pid))
                        }}
                        size="small"
                        disabled={arePidErrors}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>

                  {Array.from({ length: input.channels - 1 || 0 }, (_, inputChannelIndex) => (
                    <TableRow key={inputChannelIndex}>{checkboxRow(input, inputIndex, inputChannelIndex + 2)}</TableRow>
                  ))}
                </Fragment>
              ))}
              <TableRow>
                <TableCell sx={{ borderBottom: 0, py: 2 }}>
                  <Stack direction="row" alignItems="center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setInputs([...inputs, { pid: inputs[inputs.length - 1]?.pid + 1 || 32, channels: 2 }])
                        setInputPids([...inputPids, inputs[inputs.length - 1]?.pid + 1 || 32])
                      }}
                      disabled={arePidErrors}
                    >
                      Add input
                    </Button>
                  </Stack>
                </TableCell>
                {outputs.map((output, outputIndex) => (
                  <TableCell
                    key={outputIndex}
                    colSpan={output.channels || 1}
                    sx={{ ...cellBackground(outputIndex, 0), borderBottom: 0 }}
                  >
                    <Stack direction="row" alignItems={'center'} justifyContent={'center'}>
                      <Button
                        color="error"
                        variant="text"
                        onClick={() => {
                          setOutputs(outputs.filter((_, i) => i !== outputIndex))
                          setOutputPids(outputPids.filter((_, i) => i !== outputIndex))
                          setMappings(mappings.filter((m) => m.output.pid !== output.pid))
                        }}
                        size="small"
                        disabled={arePidErrors}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </TableCell>
                ))}
                <TableCell sx={{ borderBottom: 0 }} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="column" spacing={3} sx={{ mt: 3, width: '100%' }}>
          <Divider />
          <Stack
            direction="row"
            spacing={5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexWrap={'wrap'}
            useFlexGap
          >
            <ViewOptions />
          </Stack>
          <Divider />
          <Stack direction="row" spacing={2} alignItems="center" flexWrap={'wrap'} useFlexGap>
            <Typography variant="h5">Passthrough</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                type="number"
                label="Input PID"
                size="small"
                sx={{ width: 120 }}
                value={inputPassthroughPid}
                onChange={(e) => setInputPassthroughPid(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleAddPassthrough()}
                error={pidHelperText(parseInt(inputPassthroughPid), true) !== undefined}
                helperText={pidHelperText(parseInt(inputPassthroughPid), true)}
              />
              <ArrowForwardIcon />
              <TextField
                type="number"
                label="Output PID"
                size="small"
                sx={{ width: 120 }}
                value={outputPassthroughPid}
                onChange={(e) => setOutputPassthroughPid(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleAddPassthrough()}
                error={pidHelperText(parseInt(outputPassthroughPid), true) !== undefined}
                helperText={pidHelperText(parseInt(outputPassthroughPid), true)}
              />
            </Stack>
            <Button
              variant="contained"
              onClick={handleAddPassthrough}
              disabled={
                pidHelperText(parseInt(outputPassthroughPid), true) !== undefined ||
                pidHelperText(parseInt(inputPassthroughPid), true) !== undefined ||
                isNaN(parseInt(inputPassthroughPid)) ||
                isNaN(parseInt(outputPassthroughPid))
              }
            >
              Add
            </Button>
          </Stack>
          {passthrough.length > 0 && (
            <Stack direction="column" alignItems="flex-start" spacing={2}>
              {passthrough
                .sort((a, b) => {
                  if (selectedSortMappingsBy === 'Input') {
                    return a.inputPid === b.inputPid ? a.outputPid - b.outputPid : a.inputPid - b.inputPid
                  }
                  return a.outputPid === b.outputPid ? a.inputPid - b.inputPid : a.outputPid - b.outputPid
                })
                .map((p, index) => (
                  <Chip
                    key={index}
                    icon={<CableIcon />}
                    sx={{ fontWeight: 'bolder' }}
                    variant="outlined"
                    label={`${p.inputPid} → ${p.outputPid}`}
                    onDelete={() => {
                      setPassthrough(passthrough.filter((p1) => p1 !== p))
                    }}
                  />
                ))}
            </Stack>
          )}
          <Divider />
          <Mappings />
          {/* Since we might start with an uncontrolled state (ffmpeg params are not initialized), we must wait with rendering this component*/}
          {!loading && (
            <Select
              label="Audio codec"
              name="deriveFrom.ingestTransform.ffmpegParams.audioCodec"
              options={[
                { name: 'AAC', value: 'aac' },
                { name: 'MP2', value: 'mp2' },
                { name: 'AC3', value: 'ac3' },
              ]}
              required
              tooltip="Output audio codec will be applied to all reshuffled streams"
            />
          )}
        </Stack>
      </Paper>
    </>
  )
}
