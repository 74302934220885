import {
  GroupInputPermission,
  Input,
  InputAccessType,
  InputInit,
  InputPcapRequest,
  ListResult,
  Output,
  OutputRecipientList,
  RerouteInputPayload,
  User,
} from 'common/api/v1/types'

import { PermissionAction } from '../../api/inputs/api'
import { EnrichedInput, InputsRequestParams } from '../../api/nm-types'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { pluralize, sleep, withDefaultPagination } from '../../utils'
import { getLocationParams } from '../../utils/params'

export enum DraftActions {
  delete = 'delete',
  share = 'share',
  send = 'send',
  disable = 'disable',
}

export interface Draft {
  inputs: Array<Input>
  action?: DraftActions
}

export const createInput = createAsyncThunk<void, InputInit, ThunkApi>(
  'inputs/createInput',
  async (inputInit, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      const input = await api.inputApi.createInput(inputInit)
      navigate()(routes.inputs())
      dispatch(enqueueSuccessSnackbar(`Created Input: ${input.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'create input' }))
      throw err
    }
  },
)

export const updateInput = createAsyncThunk<void, Input, ThunkApi>(
  'inputs/updateInput',
  async (input, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      await api.inputApi.updateInput(input.id, input)
      navigate()(routes.inputs())
      dispatch(enqueueSuccessSnackbar(`Edited Input: ${input.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update input' }))
      throw err
    }
  },
)

export const getInputs = createAsyncThunk<ListResult<EnrichedInput>, Partial<InputsRequestParams>, ThunkApi>(
  'inputs/getInputs',
  async (params, { dispatch, extra: { api } }) => {
    try {
      return await api.inputApi.getInputs(withDefaultPagination(params))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch inputs' }))
      throw err
    }
  },
)

export const clearInputFormErrors = createAction('inputs/clearInputFormErrors')
export const clearInputs = createAction('inputs/clearInputs')

export const removeInput = createAsyncThunk<void, Input['id'], ThunkApi>(
  'inputs/removeInput',
  async (inputId, { dispatch, extra: { api } }) => {
    try {
      const input = await api.inputApi.removeInput(inputId)
      dispatch(enqueueSuccessSnackbar(`Removed Input: ${input.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete input' }))
      throw err
    }
  },
)

export const rerouteInput = createAsyncThunk<void, { inputId: Input['id'] } & RerouteInputPayload, ThunkApi>(
  'inputs/rerouteInput',
  async ({ inputId, avoidApplianceId }, { dispatch, extra: { api } }) => {
    try {
      const input = await api.inputApi.rerouteInput(inputId, avoidApplianceId)
      dispatch(enqueueSuccessSnackbar(`Rerouted Input: ${input.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'reroute input' }))
      throw err
    }
  },
)

export const pcapInput = createAsyncThunk<void, { inputId: Input['id'] } & InputPcapRequest, ThunkApi>(
  'inputs/pcapInput',
  async ({ inputId, applianceId }, { dispatch, extra: { api } }) => {
    try {
      await api.inputApi.pcapInput(inputId, applianceId)
      dispatch(enqueueSuccessSnackbar(`Initiated packet capture`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'initiating packet capture' }))
      throw err
    }
  },
)

export const removeInputs = createAsyncThunk<void, Array<Input['id']>, ThunkApi>(
  'inputs/removeInputs',
  async (ids, { dispatch, extra: { api } }) => {
    try {
      await api.inputApi.removeInputs(ids)
      dispatch(getInputs(withDefaultPagination(getLocationParams()) as Partial<InputsRequestParams>))
      dispatch(enqueueSuccessSnackbar(`${pluralize(ids.length, 'input')} removed`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: `delete ${pluralize(ids.length, 'input')}` }))
      throw err
    }
  },
)

export interface UpdateInputDistributionPayload {
  id: Input['id']
  permissions: Array<{
    permission: GroupInputPermission
    action: PermissionAction
  }>
  listPermissions: { [groupListId: string]: InputAccessType }
}

export const updateInputDistribution = createAsyncThunk<void, UpdateInputDistributionPayload, ThunkApi>(
  'inputs/updateInputDistribution',
  async ({ id, permissions, listPermissions }, { dispatch, extra: { api }, getState }) => {
    try {
      await api.inputApi.updateInputDistribution(id, permissions, listPermissions)
      dispatch(enqueueSuccessSnackbar('Updated input share configuration'))
      const group = (getState().userReducer.user as User).group
      if (permissions.find((i) => i.permission.groupId === group)) {
        dispatch(getInputs(withDefaultPagination(getLocationParams()) as Partial<InputsRequestParams>))
      }
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update input sharing' }))
      throw err
    }
  },
)

export const draftInputs = createAction<{
  action?: DraftActions
  inputs: Array<Input>
}>('inputs/draftInputs')

export const enableInputs = createAsyncThunk<Input[], Input['id'][], ThunkApi>(
  'inputs/enableInputs',
  async (ids, { dispatch, extra: { api } }) => {
    try {
      const res = await api.inputApi.enableInputs(ids)
      dispatch(enqueueSuccessSnackbar(`Input${ids.length > 1 ? 's' : ''} enabled`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'enable inputs' }))
      throw err
    }
  },
)

export const disableInputs = createAsyncThunk<Input[], Input['id'][], ThunkApi>(
  'inputs/disableInputs',
  async (ids, { dispatch, extra: { api } }) => {
    try {
      const res = await api.inputApi.disableInputs(ids)
      dispatch(enqueueSuccessSnackbar(`Input${ids.length > 1 ? 's' : ''} disabled`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'disable inputs' }))
      throw err
    }
  },
)

export interface UpdateInputReceiversPayload {
  id: Input['id']
  outputsAdded: Array<Output['id']>
  listsAdded: Array<OutputRecipientList['id']>
  outputsRemoved: Array<Output['id']>
}

export const updateInputRecipients = createAsyncThunk<void, UpdateInputReceiversPayload, ThunkApi>(
  'inputs/updateInputRecipients',
  async (params, { dispatch, extra: { api } }) => {
    try {
      await api.inputApi.updateInputRecipients(params)
      dispatch(enqueueSuccessSnackbar('Updated input recipients'))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'send input to outputs' }))
    }
  },
)

let hasStartedFetchingInputs = false
export const registerInputObserver = createAsyncThunk<void, { inputId: string }, ThunkApi>(
  'inputs/registerInputObserver',
  async (_, { dispatch, getState }) => {
    if (!hasStartedFetchingInputs) {
      hasStartedFetchingInputs = true

      while (true) {
        await sleep(10 * 1000)
        try {
          if ((getState().userReducer.user as User).id && !getState().userReducer.changingUser) {
            // Await so it doesn't queue up requests if the backend is slow, now it will sleep 10s after each fetch
            await dispatch(getBareInputs())
          }
        } catch {
          // nop
        }
      }
    }
  },
)
export const unregisterInputObserver = createAction<{ inputId: string }>('inputs/unregisterInputObserver')

export const getBareInputs = createAsyncThunk<ListResult<Input>, void, ThunkApi>(
  'inputs/getBareInputs',
  async (_, { getState, extra: { api } }) => {
    const inputIds = Object.keys(getState().inputsReducer.inputsToObserve)
    if (inputIds.length > 0) {
      return await api.inputApi.listInputs({ filter: { ids: inputIds } })
    } else {
      return { total: 0, items: [] }
    }
  },
)
