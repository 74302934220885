import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import Dashboard from '@mui/icons-material/Dashboard'
import OndemandVideoOutlined from '@mui/icons-material/OndemandVideoOutlined'
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew'
import Settings from '@mui/icons-material/Settings'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'
import Warning from '@mui/icons-material/Warning'
import Person from '@mui/icons-material/Person'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'
import type { Theme } from '@mui/material/styles'

import { AlarmWithImpact, BuildInfo, Role, User } from 'common/api/v1/types'
import { logoutAndNavigateToMainPage, stopImpersonation } from '../../../redux/actions/userActions'
import { toggleSidebarMenu } from '../../../redux/actions/uiActions'
import { AppDispatch, GlobalState, useRoutes } from '../../../store'
import Logo from '../../common/Logo'

import { SidebarMenuItem, type SidebarMenuItemProps } from './MenuItem'
import { useMobile } from '../../../utils/index'
import DevModeSwitch from './DevModeSwitch'
import AlarmItemNameWithActiveAlarms from './AlarmItemNameWithActiveAlarms'
import { Routes } from '../../../utils/routes'
import { IS_CONNECT_IT } from '../../../env'
import { equals } from 'common/util'

const menuItems = (
  userRole: Role,
  alarms: { items: AlarmWithImpact[]; total: number },
  routes: Routes,
  onClick: SidebarMenuItemProps['onClick'],
  devMode?: boolean,
): SidebarMenuItemProps[] => [
  {
    name: 'Overview',
    url: routes.overview(),
    icon: <Dashboard />,
    onClick,
  },
  {
    name: 'Inputs',
    url: routes.inputs(),
    icon: <VideocamOutlined />,
    onClick,
  },
  {
    name: 'Outputs',
    url: routes.outputs(),
    icon: <OndemandVideoOutlined />,
    onClick,
  },
  {
    name: <AlarmItemNameWithActiveAlarms alarms={alarms} />,
    url: routes.alarms(),
    icon: <Warning />,
    onClick,
  },
  {
    name: 'Settings',
    icon: <Settings />,
    children: [
      {
        name: 'Regions',
        url: routes.regions(),
        onClick,
      },
      {
        name: 'Appliances',
        url: routes.appliances(),
        onClick,
      },
      devMode
        ? {
            name: <s>Interfaces</s>,
            url: routes.interfaces(),
            divider: true,
            onClick,
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Networks',
            url: routes.networks(),
            onClick,
          }
        : null,
      userRole === Role.super
        ? {
            name: 'Address mappings',
            url: routes.addressMappings(),
            onClick,
          }
        : null,
      userRole === Role.super && !IS_CONNECT_IT
        ? {
            name: 'Kubernetes nodes',
            url: routes.kubernetesNodes(),
            onClick,
          }
        : null,
      userRole === Role.super && !IS_CONNECT_IT
        ? {
            name: 'Kubernetes services',
            url: routes.services(),
            divider: true,
            onClick,
          }
        : null,
      {
        name: 'Groups',
        url: routes.groups(),
        onClick,
      },
      {
        name: 'Users',
        url: routes.users(),
        divider: true,
        onClick,
      },
      {
        name: 'Audit log',
        url: routes.audit(),
        onClick,
      },
      userRole === Role.super
        ? {
            name: 'Alarm history',
            url: routes.alarmLogs(),
            divider: true,
            onClick,
          }
        : null,
      userRole === Role.super && !IS_CONNECT_IT
        ? {
            name: 'Billing',
            url: routes.billing(),
            onClick,
          }
        : null,
      userRole === Role.super || userRole === Role.admin
        ? {
            name: 'Usage',
            url: routes.usage(),
            divider: true,
            onClick,
          }
        : null,
      userRole >= Role.admin && devMode
        ? {
            name: 'Production tools',
            url: routes.production(),
          }
        : null,

      userRole === Role.super
        ? {
            name: 'Global settings',
            url: routes.globalSettings(),
            onClick,
          }
        : null,
    ].filter(Boolean),
  },
]

const styles = {
  open: {
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  },
  close: {
    transition: (theme: Theme) =>
      theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    width: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  list: {
    overflowY: 'auto',
  },
  menuBottom: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
  },
  version: {
    fontSize: '10px',
    padding: (theme: Theme) => theme.spacing(1, 2),
  },
}

interface AppVersionProps {
  buildInfo?: BuildInfo
}

const AppVersion = ({ buildInfo }: AppVersionProps) => {
  if (!buildInfo) {
    return <></>
  }

  const commit = buildInfo.commit ? `, ${buildInfo.commit}` : ''
  const buildTime = buildInfo.buildTime.toISOString().slice(0, 19)
  const versionInfo = `${buildInfo.release}, ${buildTime}${commit}`

  return <Typography sx={styles.version}>{versionInfo}</Typography>
}

export const Sidebar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { isMobile } = useMobile()
  useEffect(() => {
    dispatch(toggleSidebarMenu(!isMobile))
  }, [dispatch, isMobile])

  const { open } = useSelector(({ uiReducer }: GlobalState) => ({ open: uiReducer.open }), shallowEqual)
  const { buildInfo } = useSelector(
    ({ buildInfoReducer }: GlobalState) => ({
      buildInfo: buildInfoReducer.buildInfo,
    }),
    equals,
  )
  const { user } = useSelector(({ userReducer }: GlobalState) => ({ user: userReducer.user as User }), equals)
  const { alarms } = useSelector(
    ({ alarmsReducer }: GlobalState) => ({ alarms: alarmsReducer.alarmsForNotifications }),
    equals,
  )
  const { devMode } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ devMode: settingsReducer.devMode }),
    shallowEqual,
  )
  const { cachedUrlParams } = useSelector(
    ({ urlParamReducer }: GlobalState) => ({ cachedUrlParams: urlParamReducer.cachedUrlParams }),
    equals,
  )
  // cachedUrlParams: referenced only in order to reload the menu items (and their associated hrefs) when the stored url params are updated in Redux
  void cachedUrlParams
  const logoutUserAction = () => void dispatch(logoutAndNavigateToMainPage())
  const stopImpersonationAction = () => void dispatch(stopImpersonation({ showSuccessSnackbar: true }))
  const routes = useRoutes()
  const handleOpen = () => dispatch(toggleSidebarMenu(true))
  const handleClose = () => dispatch(toggleSidebarMenu(false))
  const handleClickLogo = () => {
    navigate(routes.overview())
    if (isMobile) handleClose()
  }
  const menu = (
    <Box component="nav" id="sidebar" data-test-isopen={open} sx={styles.container}>
      <Box sx={{ paddingY: 1, paddingX: 2 }}>
        <Logo onClickLogo={handleClickLogo} />
      </Box>
      <Divider />
      <List id="menu" disablePadding sx={styles.list}>
        {menuItems(user.role, alarms, routes, isMobile ? handleClose : undefined, devMode).map((item, ind) => {
          return <SidebarMenuItem {...item} key={item.url || ind} />
        })}
      </List>
      <Divider />
      <Box sx={styles.menuBottom}>
        <Divider />
        <SidebarMenuItem
          name={user.username}
          icon={<Person />}
          key="/profile"
          url={routes.profile.route}
          id="profile"
          secondaryText={
            user.impersonatedBy && (
              <Typography variant="body2" component="div">
                <span style={{ opacity: 0.5 }}>
                  (by <span data-test-id="current-impersonator">{user.impersonatedBy.username}</span>)
                </span>
              </Typography>
            )
          }
        />
        <Divider />
        {user.impersonatedBy ? (
          <SidebarMenuItem
            name="Switch to admin"
            icon={<SwitchAccountIcon />}
            id="stop-impersonation"
            onClick={stopImpersonationAction}
          />
        ) : (
          <SidebarMenuItem
            name="Log out"
            icon={<PowerSettingsNew />}
            key="/logout"
            id="logout-button"
            onClick={logoutUserAction}
          />
        )}
        <DevModeSwitch>
          <AppVersion buildInfo={buildInfo} />
        </DevModeSwitch>
      </Box>
    </Box>
  )

  return isMobile ? (
    <SwipeableDrawer
      anchor="left"
      disableSwipeToOpen
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      sx={{ visibility: open !== true ? 'hidden' : undefined }}
    >
      {menu}
    </SwipeableDrawer>
  ) : (
    <Drawer variant="persistent" anchor="left" open={open !== false} sx={open !== false ? styles.open : styles.close}>
      {menu}
    </Drawer>
  )
}
