import { listOverlays } from '../../../redux/actions/overlaysActions'
import { Paper, Select, TextInput } from '../../../components/common/Form'
import { AppDispatch, GlobalState } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import { InputFormProps, MAX_MAX_BITRATE_MBPS } from '../Edit/InputForm'
import Divider from '@mui/material/Divider'
import { useEffect } from 'react'
import { equals } from 'common/util'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

const MAX_MAX_BITRATE_AAC = 1000

export const TranscodingSection = () => {
  const { getValues, setValue, watch } = useFormContext<InputFormProps & AllowUncontrolled>()
  const values = getValues()

  const overlays = useSelector(({ overlaysReducer }: GlobalState) => overlaysReducer.overlays, equals)
  const dispatch = useDispatch<AppDispatch>()

  // Watch specific fields that control conditional rendering
  const paramsKey = 'deriveFrom.ingestTransform.ffmpegParams'
  watch(`${paramsKey}.codec`)
  watch(`${paramsKey}.overlay`)
  watch(`${paramsKey}.resolution`)
  watch(`${paramsKey}.pixelFormat`)

  useEffect(() => {
    if (overlays.length === 0) dispatch(listOverlays())
  }, [])

  const ffmpegParams =
    values?.deriveFrom?.ingestTransform?.type === 'transcode'
      ? values?.deriveFrom?.ingestTransform.ffmpegParams
      : undefined
  const isVideoCodec = !!ffmpegParams?.codec
  const isOverlay = !!ffmpegParams?.overlayId
  const isResolution = !!ffmpegParams?.resolution
  const isPixelFormat = !!ffmpegParams?.pixelFormat
  const isAudioCodec = !!ffmpegParams?.audioCodec
  const isAac = ffmpegParams?.audioCodec === 'aac'
  const isAacQuality = !!ffmpegParams?.audioAacQuality
  const isDeinterlacing = !!ffmpegParams?.deinterlace

  useEffect(() => {
    // If codec is not set, reset dependent fields
    if (!isVideoCodec) {
      setValue('transcodeBitrateMbps', '')
      setValue(`${paramsKey}.resolution`, undefined)
      setValue(`${paramsKey}.overlayId`, undefined)
      setValue(`${paramsKey}.pixelFormat`, undefined)
      setValue(`${paramsKey}.framerate`, undefined)
    }
  }, [isVideoCodec, setValue])

  useEffect(() => {
    if (values?.deriveFrom?.ingestTransform?.type === 'transcode')
      if (values?.deriveFrom?.ingestTransform?.ffmpegParams?.type !== 'transcode')
        setValue(`deriveFrom.ingestTransform.ffmpegParams.type`, 'transcode')
  }, [values?.deriveFrom?.ingestTransform])

  return (
    <Paper title="Transcoding">
      <Select
        label="Video codec"
        name="deriveFrom.ingestTransform.ffmpegParams.codec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'H.264 (AVC)', value: 'h264' },
          { name: 'H.265 (HEVC)', value: 'h265' },
        ]}
        tooltip="Change codec using hardware acceleration"
      />
      {isVideoCodec && (
        <>
          <TextInput
            label="Bitrate (Mbps)"
            name="transcodeBitrateMbps"
            noNegative
            validators={{
              number: {
                lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
                greaterThan: 0,
                message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
              },
            }}
            type="number"
            tooltip="Choose the compression level by selecting a bitrate for the transformed input"
          />
          <Select
            label="Framerate"
            name="deriveFrom.ingestTransform.ffmpegParams.framerate"
            options={[{ name: 'Keep original', value: '' }].concat(
              [
                { name: '25', value: '25' },
                { name: '29.97', value: '30000/1001' },
                { name: '50', value: '50' },
                { name: '59.94', value: '60000/1001' },
              ].map((framerate) => ({ name: framerate.name, value: framerate.value })),
            )}
            tooltip="Convert framerate up or down, dropping extra frames or duplicating intermediate frames"
          />
          <Select
            label={!isResolution ? 'Pixel format' : 'Pixel format (cannot be used with resolution scaling)'}
            name="deriveFrom.ingestTransform.ffmpegParams.pixelFormat"
            options={[
              { name: 'Keep original', value: '' },
              { name: 'YUV 4:2:0 Progressive', value: 'yuv420p' },
              { name: 'YUV 4:2:2 Progressive', value: 'yuv422p', disabled: true },
              { name: 'YUV 4:4:4 Progressive', value: 'yuv444p' },
            ]}
            tooltip="Pixel format choice depends on the hardware accelerator's capability. Cannot be used with resolution scaling"
            disabled={isResolution}
          />
          <Select
            label={
              !(isResolution || isDeinterlacing)
                ? 'Overlay'
                : 'Overlay (cannot be used with resolution scaling or deinterlacing)'
            }
            name="deriveFrom.ingestTransform.ffmpegParams.overlayId"
            options={[
              { name: 'None', value: '' },
              ...overlays.map((overlay) => ({ name: overlay.name, value: overlay.id })),
            ]}
            tooltip="Add a channel logo or other overlays to the transformed input. Cannot be used with resolution scaling"
            disabled={isResolution || isDeinterlacing}
          />
          <Select
            label={
              !(isOverlay || isPixelFormat)
                ? 'Resolution scaling'
                : 'Resolution scaling (cannot be used with overlay or pixel format)'
            }
            name="deriveFrom.ingestTransform.ffmpegParams.resolution"
            options={[
              { name: 'Keep original', value: '' },
              { name: '2160p', value: '3840:2160' },
              { name: '1080p', value: '1920:1080' },
              { name: '720p', value: '1280:720' },
            ]}
            tooltip="Scale the resolution of the transformed input (Only available with NVIDIA accelerators). Cannot be used with overlay or pixel format"
            disabled={isOverlay || isPixelFormat}
          />
          <Select
            label={!isOverlay ? 'Deinterlace' : 'Deinterlace (cannot be used with overlay)'}
            name="deriveFrom.ingestTransform.ffmpegParams.deinterlace"
            options={[
              { name: 'Keep original', value: '' },
              { name: 'Deinterlace', value: 'yadif' },
            ]}
            tooltip="Enable or disable YADIF deinterlacing. Only enable on interlaced video inputs. Cannot be used with overlays"
            disabled={isOverlay}
          />
        </>
      )}

      <Divider sx={{ width: '100%', mt: 3 }} />

      <Select
        label="Audio codec"
        name="deriveFrom.ingestTransform.ffmpegParams.audioCodec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'AAC', value: 'aac' },
          { name: 'MP2', value: 'mp2' },
          { name: 'AC3', value: 'ac3' },
        ]}
        tooltip="Change audio codec"
      />

      {isAudioCodec && (
        <TextInput
          label="Audio Bitrate (kbps)"
          name="transcodeAudioBitrateKbps"
          noNegative
          validators={{
            number: {
              lessThanOrEqualTo: MAX_MAX_BITRATE_AAC,
              greaterThan: 0,
              message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_AAC}`,
            },
          }}
          type="number"
          disabled={isAacQuality}
        />
      )}

      {isAac && (
        <>
          <Select
            label="AAC Profile"
            name="deriveFrom.ingestTransform.ffmpegParams.audioAacProfile"
            options={[
              { name: 'LC', value: 'LC' },
              { name: 'HE', value: 'HE' },
              { name: 'HEv2', value: 'HEv2' },
            ]}
          />

          <Select
            label="AAC Quality"
            name="deriveFrom.ingestTransform.ffmpegParams.audioAacQuality"
            options={[
              { name: 'Disable', value: '' },
              { name: 'Low', value: 'low' },
              { name: 'Medium', value: 'medium' },
              { name: 'High', value: 'high' },
            ]}
            tooltip="Uses a variable bitrate target. Overrides the constant bitrate setting chosen in: Audio Bitrate (kbps)"
          />
        </>
      )}
    </Paper>
  )
}
