import React from 'react'
import { createPortal } from 'react-dom'
import MuiGrid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import { Address, Output, ZixiLink, ZixiLinkMode, ZixiPushOutputPort } from 'common/api/v1/types'
import { Paper, Select, TextInput } from '../../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../../utils'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'
import GridItem from '../../../../../common/Form/GridItem'

export enum LinkFields {
  localIp = 'localIp',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  rateLimit = 'rateLimit',
}
export const linkSetDefault = createDefaultFiledValues(Object.keys(LinkFields), [], {
  [LinkFields.remotePort]: 2088,
  [LinkFields.rateLimit]: 1000000,
}) as { [key: string]: number | string }

export type ZixiPushOutput = Output & { ports: Array<ZixiPushOutputPort> }
const LinksForm = ({
  namePrefix,
  isStandby,
  addresses,
}: {
  namePrefix: string
  isStandby: boolean
  addresses: Address[]
}) => {
  const { getValues } = useFormContext()

  const { fields, remove } = useFieldArray({
    name: namePrefix,
  })

  return (
    <MuiGrid item xs={12}>
      {fields &&
        fields.length > 0 &&
        (isStandby ? [fields[0]] : fields).map((field: any, idx: number) => {
          const propertyNamePrefix = `${namePrefix}.${idx}`
          const localAddressSelector = `${propertyNamePrefix}.${LinkFields.localIp}`
          return (
            <Paper key={field.id}>
              <Select
                name={localAddressSelector}
                label="Local address"
                options={makeAddressOptions(getValues(localAddressSelector), addresses)}
                required
                newLine
              />
              <TextInput
                name={`${propertyNamePrefix}.${LinkFields.remoteIp}`}
                label="Remote host"
                required
                validators={{ ipOrHostname: {} }}
              />
              <TextInput
                name={`${propertyNamePrefix}.${LinkFields.remotePort}`}
                label="Remote UDP port"
                required
                type="number"
                validators={{ port: { isUdp: true } }}
              />
              {!isStandby && (
                <TextInput
                  name={`${propertyNamePrefix}.${LinkFields.rateLimit}`}
                  label="Rate limit (kbps)"
                  type="number"
                  noNegative
                  validators={{
                    number: {
                      lessThanOrEqualTo: Math.pow(2, 32) - 1,
                      message: `Must be no more than ${Math.pow(2, 32) - 1}`,
                    },
                  }}
                />
              )}
              {fields.length > 1 && !isStandby && (
                <GridItem newLine>
                  <Button variant="outlined" color="primary" onClick={() => remove(idx)}>
                    Remove link
                  </Button>
                </GridItem>
              )}
            </Paper>
          )
        })}
    </MuiGrid>
  )
}

interface LinksArrayProps {
  addresses: Address[]
  namePrefix: string
}
interface LinksPaperProps extends LinksArrayProps {
  title: string
  isFirstSet: boolean
}
const LinksPaper: React.FunctionComponent<LinksPaperProps> = ({ title, addresses, namePrefix, isFirstSet }) => {
  const { getValues, setValue } = useFormContext<ZixiPushOutput & AllowUncontrolled>()

  const prop = `${namePrefix}.${isFirstSet ? 'linkSet1' : 'linkSet2'}`
  useWatch({ name: prop })
  const port: ZixiPushOutputPort = getValues(namePrefix)
  const isStandby = port.linkMode === ZixiLinkMode.standby
  const linkSet = port[isFirstSet ? 'linkSet1' : 'linkSet2']
  return (
    <Paper
      className="outlined"
      title={title}
      actionsPane={[
        {
          id: `add-link-btn-${isFirstSet ? 1 : 2}`,
          title: 'Add link',
          onClick: () =>
            setValue(
              prop,
              linkSet ? linkSet.concat({ ...linkSetDefault } as unknown as ZixiLink) : [{ ...linkSetDefault }],
            ),
          props: { disabled: isStandby || port.linkSet1.length > 2 },
        },
      ]}
    >
      <LinksForm namePrefix={prop} addresses={addresses} isStandby={port.linkMode === ZixiLinkMode.standby} />
    </Paper>
  )
}
const LinksArray: React.FunctionComponent<LinksArrayProps> = ({ namePrefix, addresses }) => {
  const { getValues } = useFormContext<ZixiPushOutput & AllowUncontrolled>()

  const container = document.getElementById('LinksArrayContainer')
  if (!container) return null
  const isStandby = getValues(`${namePrefix}.linkMode`) === ZixiLinkMode.standby
  return createPortal(
    <>
      <LinksPaper
        title={`Primary${isStandby ? '' : ' links'}`}
        isFirstSet={true}
        addresses={addresses}
        namePrefix={namePrefix}
      />
      <LinksPaper
        title={`Standby${isStandby ? '' : ' links'}`}
        isFirstSet={false}
        addresses={addresses}
        namePrefix={namePrefix}
      />
    </>,
    container,
  )
}

export default LinksArray
