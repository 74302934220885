import Button from '@mui/material/Button'

import { PortMode, PortType } from 'common/api/v1/types'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm/CoaxPortForm'
import NdiForm from './NdiForm'
import EncoderSettings from '../EncoderSettings'
import { PhysicalPortInfoWithAppliance } from '../../../../api/nm-types'
import { GridItem } from '../../../common/Form'

interface IPortFormProps {
  index: number
  physicalPort: PhysicalPortInfoWithAppliance
  isModeDisabled: boolean
  namePrefix: string
  enforcedPortMode: PortMode | undefined
  onRemoveLogicalPortRequested: () => void
  onAddLogicalPortRequested: () => void
}

export const PortForm = ({
  physicalPort,
  isModeDisabled,
  namePrefix,
  enforcedPortMode,
  index,
  onAddLogicalPortRequested,
  onRemoveLogicalPortRequested,
}: IPortFormProps) => {
  return (
    <>
      {physicalPort.portType === PortType.ip && (
        <IpPortForm
          namePrefix={namePrefix}
          addresses={physicalPort.addresses}
          applianceId={physicalPort.appliance.id}
          applianceType={physicalPort.appliance.type}
          applianceVersion={physicalPort.appliance.version}
          applianceFeatures={physicalPort.appliance.features}
          index={index}
          occupiedPorts={physicalPort.occupiedPorts}
          isModeDisabled={isModeDisabled}
          enforcedPortMode={enforcedPortMode}
          onAddLogicalPortRequested={onAddLogicalPortRequested}
          onRemoveLogicalPortRequested={onRemoveLogicalPortRequested}
        />
      )}

      {physicalPort.portType === PortType.coax && (
        <CoaxPortForm
          physicalPortIndex={physicalPort.index ?? 'Missing port index'}
          namePrefix={namePrefix}
          settings={physicalPort.appliance.settings}
          applianceFeatures={physicalPort.appliance.features}
        />
      )}

      {physicalPort.portType === PortType.ndi && <NdiForm namePrefix={namePrefix} />}

      <GridItem newLine>
        <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={onRemoveLogicalPortRequested}>
          Remove interface
        </Button>
      </GridItem>

      <EncoderSettings namePrefix={namePrefix} applianceFeatures={physicalPort.appliance.features} />
    </>
  )
}
