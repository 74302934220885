import { useState } from 'react'
import { format } from 'date-fns'
import { DATE_FORMAT_SHORT } from 'common/api/v1/helpers'
import { useConfirmationDialog, useUser } from '../../utils'
import { MissingContent } from '../common/MissingContent'
import {
  ACCESS_SCOPE_NAMES_LIST,
  ACCESS_SCOPE,
  ApiToken,
  ApiTokenInit,
  ACCESS_SCOPE_FRIENDLY_NAMES,
  ACCESS_SCOPE_DESCRIPTIONS,
} from 'common/api/v1/types'
import { useAsync } from 'react-async-hook'
import { Api, AppDispatch } from '../../store'
import { FailedFetchingContent } from '../common/FailedFetchingContent'
import { useDispatch } from 'react-redux'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../../redux/actions/notificationActions'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Grid2 from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Form, Paper, RHF, TextInput, Checkbox, DatePicker } from '../common/Form'
import { Table, TableConfig } from '../common/Table'
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

// make it easier to work with checkboxes (by converting scopes array to record for the form)
type ScopesRecord = Record<ACCESS_SCOPE, boolean>
type APITokenInitConfigForm = Omit<ApiTokenInit, 'scopes' | 'role'> & {
  scopes: ScopesRecord
}

const handleCopy = (value: string) => void navigator.clipboard.writeText(value)

const convertScopesArrayToRecord = (scopes: ACCESS_SCOPE[]) =>
  Object.fromEntries(scopes.map((scope) => [scope, false])) as ScopesRecord

const convertScopesRecordToArray = (scopes: ScopesRecord) => {
  return Object.entries(scopes)
    .filter(([, value]) => !!value)
    .map(([key]) => key) as ACCESS_SCOPE[]
}
const ScopesDefaultValuesObj = convertScopesArrayToRecord(ACCESS_SCOPE_NAMES_LIST)

const fieldErrorToString = (error: FieldError | undefined) => {
  if (!error) return ''
  if (error.type === 'required') {
    return 'This field is required'
  }
  return error.message
}

interface APITokenConfigFormProps {
  onCancel: () => void
}

const APITokenConfigForm = (props: APITokenConfigFormProps) => {
  const { control } = useFormContext<APITokenInitConfigForm>()

  return (
    <Form id="access-token-form">
      <Stack direction={'column'} spacing={2}>
        <TextInput name="name" label="Token name" required autoFocus />
        <Controller
          name={'expiresAt'}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <DatePicker
                name="Expiry Date"
                format="dd/MM/yyyy"
                label="Expiry Date"
                minDate={new Date()}
                value={field.value}
                onChange={(date) => {
                  field.onChange(date?.toISOString())
                }}
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText: fieldErrorToString(error),
                  },
                }}
              />
            )
          }}
        />

        <Grid2 marginTop={2} width={'100%'}>
          <Typography variant="h5">Scopes</Typography>
          <Typography variant="body1" color="textSecondary">
            Pick at least one scope that this token will have access to
          </Typography>
          {ACCESS_SCOPE_NAMES_LIST.map((scope) => (
            <Checkbox
              key={scope}
              name={`scopes.${scope}`}
              label={ACCESS_SCOPE_FRIENDLY_NAMES[scope]}
              tooltip={ACCESS_SCOPE_DESCRIPTIONS[scope]}
            />
          ))}
        </Grid2>

        <Stack direction="row" spacing={2} marginTop={2}>
          <Button variant="outlined" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="contained" sx={{ marginTop: 1 }}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

interface APITokenFormProps {
  onCancel: () => void
  onSuccess: (token: string) => void
}

export const APITokenForm = (props: APITokenFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const user = useUser()

  const onSubmit = (values: APITokenInitConfigForm) => {
    const apiToken: ApiTokenInit = {
      ...values,
      role: user.role,
      scopes: convertScopesRecordToArray(values.scopes),
    }

    Api.apiTokensApi
      .createApiToken(apiToken)
      .then((response) => {
        dispatch(enqueueSuccessSnackbar(`Created API token ${apiToken.name}`))
        props.onSuccess(response.token)
      })
      .catch((error) => {
        dispatch(enqueueErrorSnackbar({ error, operation: 'create API token' }))
      })
  }

  return (
    <RHF
      onSubmit={onSubmit}
      defaultValues={{
        name: '',
        expiresAt: undefined as unknown as Date,
        scopes: ScopesDefaultValuesObj,
      }}
      enableReinitialize
      component={() => <APITokenConfigForm onCancel={props.onCancel} />}
    />
  )
}

export const APITokensTable = () => {
  const showConfirm = useConfirmationDialog()
  const dispatch = useDispatch<AppDispatch>()

  const [createTokenMode, setCreateTokenMode] = useState(false)
  const [showToken, setShowToken] = useState('')

  // state to force refresh of the table
  const [refreshId, setRefreshId] = useState(0)
  const refresh = () => setRefreshId((id) => id + 1)

  const removeApiToken = (token: ApiToken) => {
    showConfirm(() => {
      Api.apiTokensApi
        .removeApiToken(token.id)
        .then(() => {
          dispatch(enqueueSuccessSnackbar('Removed Token'))
          refresh()
        })
        .catch((error) => {
          dispatch(enqueueErrorSnackbar({ error, operation: 'delete API token' }))
        })
    }, `Are you sure you want to delete api token '${token.name}'?`)
  }

  const { result, loading, error } = useAsync(async () => Api.apiTokensApi.getApiTokens(), [refreshId])

  const apiTokens = result?.items || []

  const tableConfig: TableConfig<ApiToken> = [
    {
      title: 'Token name',
      getValue: ({ name }) => <span style={{ paddingLeft: '16px' }}>{name}</span>,
    },
    {
      title: 'Scopes',
      getValue: ({ scopes }) => (scopes ?? []).join(', '),
    },
    {
      title: 'Last used',
      getValue: ({ lastUsedAt }) => (lastUsedAt ? format(new Date(lastUsedAt), DATE_FORMAT_SHORT) : 'Never'),
    },
    {
      title: 'Expires',
      getValue: ({ expiresAt }) => (expiresAt ? format(new Date(expiresAt), DATE_FORMAT_SHORT) : 'Never'),
    },
    {
      title: 'Actions',
      getValue: (token) => (
        <IconButton onClick={() => removeApiToken(token)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]

  if (error) {
    const message = error.message ? `: ${error.message}` : ''
    return <FailedFetchingContent message={`Failed to fetch tokens${message}`} />
  }

  return (
    <Paper
      title="API Tokens"
      actionsPane={[
        <Button
          disabled={createTokenMode || !!showToken}
          variant="contained"
          color="primary"
          onClick={() => setCreateTokenMode(true)}
        >
          Add new token
        </Button>,
      ]}
    >
      <Grid2 direction="column" alignItems="flex-start" spacing={2} marginTop={2} width={'100%'}>
        <Table<ApiToken>
          emptyMessageComponent={<MissingContent message="No access tokens available" />}
          config={tableConfig}
          data={apiTokens}
          id="networks-table"
          pending={loading}
          isSmall
        />
        {createTokenMode && (
          <APITokenForm
            onCancel={() => setCreateTokenMode(false)}
            onSuccess={(createdToken) => {
              setCreateTokenMode(false)
              setShowToken(createdToken)
              refresh()
            }}
          />
        )}

        {showToken && (
          <Alert color="success" severity="success" onClose={() => setShowToken('')}>
            <AlertTitle color="primary">Token created</AlertTitle>
            <Typography variant="body1">Token: {showToken}</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ContentCopyIcon />}
              onClick={() => handleCopy(showToken)}
            >
              Copy
            </Button>
            <Typography variant="body1">
              <strong>Important:</strong> Save this token now, it will not be shown again!
            </Typography>
          </Alert>
        )}
      </Grid2>
    </Paper>
  )
}
