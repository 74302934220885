import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../common/Tooltip'

import { Appliance, Role } from 'common/api/v1/types'
import { pluralize, useConfirmationDialog } from '../../utils'
import { restartAppliances } from '../../redux/actions/applianceActions'
import { EnrichedApplianceWithOwner, EnrichedUser } from '../../api/nm-types'
import { Api, AppDispatch } from '../../store'
import {
  ListApplianceFilterKey,
  makeListApplianceFilter,
  mapApplianceFilterToUrlParam,
  mapUrlParamToApplianceFilter,
} from './listApplianceFilter'
import { UrlParamFilteredSearchBar } from '../common/Filters/FilterView/FilteredSearchBar'
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'

interface CommonActionsProps {
  appliances: EnrichedApplianceWithOwner[]
  selected: Array<Appliance['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  user: EnrichedUser
}

const BatchRestartButton = ({
  selected,
  setSelected,
  disabledMessage,
}: Pick<CommonActionsProps, 'selected' | 'setSelected'> & { disabledMessage?: string }) => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()

  const onClick = () => {
    showConfirmation(() => {
      dispatch(restartAppliances({ ids: selected }))
      setSelected([])
    }, 'Restarting will affect all video streams on the appliances. After restart the appliances will run the latest software version. Do you want to continue?')
  }

  return (
    <Tooltip title={disabledMessage ?? 'Restart selected appliances'} disablePortal={false}>
      <span>
        <Button variant="contained" color="primary" onClick={onClick} disabled={!!disabledMessage}>
          Restart
        </Button>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ selected, setSelected, appliances, user }: CommonActionsProps) => {
  const selectedAppliances = appliances.filter(({ id }) => selected.includes(id))
  const isOwnerOfAllSelected = selectedAppliances.every(({ _owner }) => _owner.id === user.group)
  const filters = makeListApplianceFilter({
    applianceApi: Api.appliancesApi,
    groupApi: Api.groupsApi,
    regionApi: Api.regionApi,
    tagApi: Api.tagApi,
  })

  return (
    <Toolbar disableGutters style={{ overflowX: 'clip' }}>
      <Box
        sx={{
          transition: (theme: Theme) =>
            theme.transitions.create('flex-shrink', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }),
        }}
        style={{
          flexShrink: selected.length > 0 ? 1 : 0,
          flexGrow: 1,
          flexBasis: '100%',
        }}
      >
        <UrlParamFilteredSearchBar
          urlParamCacheKey={'appliances'}
          mapUrlParamToFilterFn={mapUrlParamToApplianceFilter}
          mapFilterToUrlParamFn={mapApplianceFilterToUrlParam}
          searchTokenFilters={filters}
          rawTextFilter={filters.find((f) => f.key === ListApplianceFilterKey.name)!}
        />
      </Box>

      <Box
        sx={{
          transition: (theme: Theme) =>
            // Fade out, i.e. keep visible until contents has left the screen
            theme.transitions.create('visibility', {
              delay: selected.length === 0 ? theme.transitions.duration.shortest : 0,
            }),
        }}
        style={{
          marginLeft: 16,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          visibility: selected.length > 0 ? 'visible' : 'hidden',
        }}
      >
        <div style={{ width: 180, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1">{pluralize(selected.length, 'appliance')} selected</Typography>
        </div>
        <div style={{ marginLeft: 16 }}>
          <BatchRestartButton
            selected={selected}
            setSelected={setSelected}
            disabledMessage={
              user.role === Role.super || isOwnerOfAllSelected
                ? undefined
                : 'You are not the owner of a selected appliance'
            }
          />
        </div>
      </Box>
    </Toolbar>
  )
}

export default CommonActions
