import { useRef } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MUISelect from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import {
  EnrichedApplianceWithOwner,
  EnrichedInputPort,
  EnrichedOutputPort,
  PaginatedRequestParams,
  PhysicalPortInfoWithAppliance,
} from '../../../api/nm-types'
import { Api, GlobalState } from '../../../store'
import {
  Address,
  Appliance,
  ApplianceType,
  ApplianceVersion,
  BuildInfo,
  Group,
  IpPortMode,
  ListResult,
  PortBase,
  PortMode,
  Region,
  RegionalPort,
} from 'common/api/v1/types'
import { GridItem, Paper } from '../Form'

import { AutoComplete } from '../AutoComplete'
import { EnrichedOutputWithEnrichedPorts } from '../../outputs/Edit'
import { EnrichedInputWithEnrichedPorts } from '../../inputs/Edit'
import { RegionalInterfaceSection } from './Regional'
import { ApplianceInterfaceSection } from './Appliance'
import { runningDifferentSoftwareVersion } from '../../../utils'
import DelayedAppearance from '../DelayedAppearance'
import { InterfaceUnavailableReason, whyIsPhysicalPortUnavailable } from '../../../utils/physicalPortUtil'
import { PortForm as InputPortForm } from '../../inputs/Edit/PortForm'
import { PortForm as OutputPortForm } from '../../outputs/Edit/PortForm'
import { applianceTypes } from 'common/applianceTypes'
import { REACT_APP_EDGE_PRODUCT } from '../../../env'
import { equals } from 'common/util'
import { getProductName } from 'common/api/v1/helpers'
import { useFieldArray } from 'react-hook-form'
import { AllowUncontrolled, FormProps } from '../Form/RHF'
import { isRtpOverSrt } from 'common/srt'
import { ApplianceIndicatorIcon } from '../Indicator'

export const applianceSectionPaper = {
  padding: (theme: Theme) => theme.spacing(0, 0, 2),
  margin: (theme: Theme) => theme.spacing(4, 0, 0),
  boxShadow: 'none',
}

export const styles = {
  warningText: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    color: (theme: Theme) => theme.palette.warning.light,
  },
}

export const APPLIANCE_SECTION_FORM_PREFIX = '_applianceSection'

export function makeApplianceNamePrefix(applianceIndex: number): string {
  return `${APPLIANCE_SECTION_FORM_PREFIX}.${applianceIndex}`
}
export function makeAppliancePortsNamePrefix(applianceIndex: number, portIndex: number): string {
  return `${makeApplianceNamePrefix(applianceIndex)}.ports.${portIndex}`
}

export type ApplianceSectionSelectionData<T extends EnrichedInputPort | EnrichedOutputPort> = {
  region?: Pick<Region, 'id' | 'name'>
  appliance?: Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>
  ports: T[]
}

export const formatInterfaceAddress = (address: Address) => {
  const hasPublicAddress = !!address.publicAddress
  return `${address.address}${hasPublicAddress ? ` Public: ${address.publicAddress}` : ''}`
}

export function getPortAppliance<T extends EnrichedInputPort | EnrichedOutputPort>(port: T) {
  return isRegionalPort(port) ? port.region!.allocatedAppliance : port?._port?.appliance
}

export const groupPortsByApplianceOrRegion = <T extends EnrichedInputPort | EnrichedOutputPort>(
  ports: T[],
): ApplianceSectionSelectionData<T>[] => {
  const portsGroupedByApplianceOrRegion = ports.reduce((acc: ApplianceSectionSelectionData<T>[], port: T) => {
    const isRegion = isRegionalPort(port)
    const applianceOrRegion = isRegion ? port.region! : port._port._appliance ?? port._port.appliance
    const portApplianceId = getPortAppliance(port)?.id

    // Find an existing entry in the array that matches the appliance ID
    const existingEntry = acc.find((entry) => entry.ports.some((p) => getPortAppliance(p)?.id === portApplianceId))

    if (existingEntry) {
      existingEntry.ports.push(port)
    } else {
      // Create a new entry if none exists for this appliance/region
      const entry = makeApplianceSection({
        region: isRegion ? applianceOrRegion : undefined,
        appliance: isRegion ? undefined : (applianceOrRegion as any),
      })
      entry.ports.push(port)
      acc.push(entry as ApplianceSectionSelectionData<T>)
    }

    return acc
  }, [] as ApplianceSectionSelectionData<T>[])

  if (portsGroupedByApplianceOrRegion.length === 0) {
    // Ensure at least one empty section if no ports were grouped
    const applianceSection = makeApplianceSection<T>({ region: undefined, appliance: undefined })
    portsGroupedByApplianceOrRegion.push(applianceSection)
  }

  return portsGroupedByApplianceOrRegion
}

export const makeApplianceSection = <T extends EnrichedInputPort | EnrichedOutputPort>({
  region,
  appliance,
}: {
  region: Pick<Region, 'id' | 'name'> | undefined
  appliance: (Pick<Appliance, 'id' | 'name' | 'type'> & Partial<Pick<Appliance, 'settings'>>) | undefined
}): ApplianceSectionSelectionData<T> => {
  return {
    region,
    appliance,
    ports: [],
  }
}

export const collectPortsFromApplianceSections = <T extends EnrichedInputPort | EnrichedOutputPort>(
  values: object,
): T[] => {
  return collectApplianceSectionEntries(values).flatMap((value) => value.ports as T[])
}

export const collectApplianceSectionEntries = <T extends EnrichedInputPort | EnrichedOutputPort>(
  values: object,
): ApplianceSectionSelectionData<T>[] => (values as any)[APPLIANCE_SECTION_FORM_PREFIX] ?? []

/// Business logic regarding multi-appliance inputs and outputs.
export const isApplianceOrRegionSelectable = (
  applianceOrRegion: ApplianceOrRegion,
  values: ApplianceSectionForm,
  isOutputForm: boolean,
) => {
  const isRegion = !isAppliance(applianceOrRegion)
  const applianceSectionEntries = collectApplianceSectionEntries(values)
  const numberOfApplianceSections = applianceSectionEntries.length
  if (numberOfApplianceSections <= 1) {
    // Single appliance section:
    // User may or may not yet have selected an appliance or region - either way he/she is free to select/change it.
    return true
  }

  // Multiple appliance sections:
  const selectedAppliancesAndRegions = applianceSectionEntries.map((v) => v.region ?? v.appliance)
  const firstSelectedApplianceOrRegion = selectedAppliancesAndRegions.find(Boolean)
  if (!firstSelectedApplianceOrRegion) {
    // User has two empty appliance sections but with no appliance or region selected (can happen if they clear the selected value)
    if (isOutputForm) {
      // For output: Must select a region or a core appliance (multi-appliance outputs are restricted to core nodes)
      return isRegion || applianceOrRegion.type === ApplianceType.core
    }
    // For input: free to select any appliance or region
    return true
  }
  if (isAppliance(firstSelectedApplianceOrRegion)) {
    // User has previously selected an appliance
    if (isRegion) {
      // The second selection must also be an appliance (not a region)
      return false
    }
    const isApplianceAlreadySelected = !!selectedAppliancesAndRegions.find(
      (a) => a && isAppliance(a) && a.id === applianceOrRegion.id,
    )
    if (isApplianceAlreadySelected) {
      // The second appliance must be a different one
      return false
    }

    if (firstSelectedApplianceOrRegion.type === ApplianceType.core) {
      // The second appliance must be of the same type, where type is defined to be either a "core appliance" (located in the cluster)
      // or "edge appliance" (located outside of the cluster).
      return applianceOrRegion.type === ApplianceType.core
    } else {
      if (isOutputForm) {
        // For output: Multi-appliance outputs are restricted to core nodes
        return false
      }
      // For input: The second appliance must also be a non-core type
      return applianceOrRegion.type !== ApplianceType.core
    }
  }

  // User has previously selected a region - the second selection must also be a region
  return isRegion
}

export type ApplianceOrRegion =
  | (Pick<Appliance, 'id' | 'name' | 'type' | 'settings' | 'health'> & Partial<Pick<Appliance, 'version'>>)
  | Pick<Region, 'id' | 'name'>
export type ApplianceSectionForm = EnrichedInputWithEnrichedPorts | EnrichedOutputWithEnrichedPorts

interface BaseFormProps {
  applianceIndex: number
  namePrefix: string
  title: string
  onRemove?: (applianceIndex: number) => void
  isInputForm: boolean
  isEditingExistingEntity: boolean
  isCopyingExistingEntity: boolean
  inputId: string | undefined
  outputId: string | undefined
  groupId: Group['id']
  initialApplianceOrRegionId: string | undefined
  enforcedPortMode: PortMode | undefined
  isModeSelectionDisabled: boolean
  adminStatus: 0 | 1
  isApplianceOrRegionSelectable: (applianceOrRegion: ApplianceOrRegion) => boolean
  onApplianceOrRegionSelected: (applianceOrRegion: ApplianceOrRegion | null) => void
}

export const Loading = ({ message }: { message: string }) => (
  <DelayedAppearance gracePeriodMs={1000}>
    <GridItem newLine>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Typography component="div" variant="body1">
          {message}
        </Typography>
        <CircularProgress style={{ marginLeft: 20 }} />
      </div>
    </GridItem>
  </DelayedAppearance>
)
export const Error = ({ message }: { message: string }) => (
  <GridItem newLine>
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Typography component="div" variant="body1">
        {message}
      </Typography>
    </div>
  </GridItem>
)
export function LogicalPortForm<T extends EnrichedInputPort | EnrichedOutputPort | RegionalPort>({
  isRegional,
  namePrefix,
  logicalPorts,
  fieldArray: { fields, update, remove },
  isInputForm,
  adminStatus,
  appliancePhysicalPorts,
  isModeSelectionDisabled,
  enforcedPortMode,
  restrictPhysicalPortsToGroupId,
  onAddLogicalPortRequested,
}: {
  isRegional: boolean
  namePrefix: string
  logicalPorts: T[]
  fieldArray: ReturnType<typeof useFieldArray>
  isInputForm: boolean
  adminStatus: 0 | 1
  appliancePhysicalPorts: PhysicalPortInfoWithAppliance[]
  isModeSelectionDisabled: boolean
  enforcedPortMode: PortMode | undefined
  restrictPhysicalPortsToGroupId?: string
  onAddLogicalPortRequested: () => void
}) {
  return (
    <>
      {fields.map((field, portIndex) => {
        const logicalPort = logicalPorts[portIndex]
        if (!logicalPort) return null // RHF FieldArray may not be in sync with logicalPorts

        const physicalPort = appliancePhysicalPorts.find((p) => p.id == logicalPort.physicalPort)
        if (!physicalPort) return null

        const isPrimaryInterface = portIndex === 0
        const interfaceText = `${isRegional ? 'Regional ' : ''}Interface${
          isPrimaryInterface ? '' : `-${portIndex + 1}`
        }`
        const interfaceToolTip = isPrimaryInterface
          ? undefined
          : `Additional interface to ${isInputForm ? 'receive' : 'egress'} the same stream for redundancy.`

        return (
          <Paper key={field.id} sx={applianceSectionPaper}>
            <GridItem tooltip={interfaceToolTip} newLine>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id={`select-input-interface-${portIndex}-label`}>{interfaceText}</InputLabel>
                <MUISelect
                  name={`${namePrefix}.ports.${portIndex}.interface`}
                  label={interfaceText}
                  labelId={`select-input-interface-${portIndex}-label`}
                  disabled={logicalPort.mode === IpPortMode.generator}
                  fullWidth
                  value={logicalPort.physicalPort}
                  onChange={(event) => {
                    const newPhysicalPort = appliancePhysicalPorts.find((p) => p.id === event.target.value)
                    update(portIndex, {
                      ...logicalPort,
                      physicalPort: newPhysicalPort?.id,
                      _port: newPhysicalPort,
                    })
                  }}
                >
                  {appliancePhysicalPorts.map((physicalPort) => {
                    const disabledReason = whyIsPhysicalPortUnavailable({
                      requestee: { groupId: restrictPhysicalPortsToGroupId },
                      physicalPort,
                    })
                    const isPhysicalPortEnabled =
                      !disabledReason ||
                      (adminStatus === 0 && disabledReason.code === InterfaceUnavailableReason.occupied)
                    const formattedAddress = physicalPort.addresses.map(formatInterfaceAddress)[0]
                    return (
                      <MenuItem key={physicalPort.id} value={physicalPort.id} disabled={!isPhysicalPortEnabled}>
                        <div>
                          <Typography>
                            {physicalPort.name}
                            {disabledReason?.msg && (
                              <Box component={'span'} sx={styles.warningText}>
                                ({disabledReason.msg})
                              </Box>
                            )}
                          </Typography>
                          {formattedAddress && (
                            <Typography component="div" variant="body2" color="textSecondary">
                              {formattedAddress}
                            </Typography>
                          )}
                        </div>
                      </MenuItem>
                    )
                  })}
                </MUISelect>
              </FormControl>
            </GridItem>

            {isInputForm ? (
              <InputPortForm
                portIndex={portIndex}
                namePrefix={`${namePrefix}.ports.${portIndex}`}
                physicalPort={physicalPort}
                isModeDisabled={isModeSelectionDisabled}
                onAddLogicalPortRequested={onAddLogicalPortRequested}
                onRemoveLogicalPortRequested={() => remove(portIndex)}
              />
            ) : (
              <OutputPortForm
                index={portIndex}
                namePrefix={`${namePrefix}.ports.${portIndex}`}
                physicalPort={physicalPort}
                isModeDisabled={isModeSelectionDisabled}
                enforcedPortMode={enforcedPortMode}
                onAddLogicalPortRequested={onAddLogicalPortRequested}
                onRemoveLogicalPortRequested={() => remove(portIndex)}
              />
            )}
          </Paper>
        )
      })}
    </>
  )
}

export const isRegionalPort = (port?: PortBase): port is RegionalPort =>
  !!port && 'region' in port && !!(port as RegionalPort).region

export const isAppliance = (
  applianceOrRegion: ApplianceOrRegion,
): applianceOrRegion is Extract<ApplianceOrRegion, { type: any }> => 'type' in applianceOrRegion

export function isCoreNode(values: ApplianceSectionForm) {
  for (const { region, appliance } of collectApplianceSectionEntries(values)) {
    if (region) return true
    if (appliance?.type === ApplianceType.core) return true
  }
  return false
}

const getApplianceSoftwareString = (
  version: ApplianceVersion,
  buildInfo: Pick<BuildInfo, 'release'> | undefined,
): string | undefined => {
  const isApplianceRunningOutdatedSoftware =
    runningDifferentSoftwareVersion(version.controlSoftwareVersion, buildInfo) ||
    runningDifferentSoftwareVersion(version.dataSoftwareVersion, buildInfo)
  return isApplianceRunningOutdatedSoftware ? '(appliance software is outdated)' : undefined
}

export const areMultipleLogicalPortsPerApplianceSupported = ({
  region,
  appliance,
  isInput,
  allLogicalPorts,
}: {
  region?: Pick<Region, 'id' | 'name'>
  appliance?: Pick<Appliance, 'id' | 'type'>
  isInput: boolean
  allLogicalPorts: Array<EnrichedInputPort | EnrichedOutputPort>
}) => {
  // All logical ports on same appliance have the same mode
  const logicalPortOnThisAppliance = allLogicalPorts.find((lp) => getPortAppliance(lp)?.id === appliance?.id)
  const portMode = logicalPortOnThisAppliance?.mode

  const supportedModes: string[] = isInput
    ? [IpPortMode.rtp, IpPortMode.udp, IpPortMode.srt]
    : [IpPortMode.rtp, IpPortMode.srt]
  const isModeSupported = supportedModes.includes(portMode ?? '')
  const isApplianceTypeSupported =
    !!region || ([ApplianceType.edgeConnect, ApplianceType.core] as string[]).includes(appliance?.type ?? '')

  // Disable "Add interface" button for all appliances with SRT-interfaces when RTP-over-SRT is enabled
  const isRtpOverSrtEnabled = allLogicalPorts.some((lp) => isRtpOverSrt(lp))
  const applianceHasSrtInterface =
    !!appliance?.id &&
    allLogicalPorts
      .filter((lp) => lp.mode === IpPortMode.srt)
      .map((lp) => getPortAppliance(lp)?.id)
      .includes(appliance.id)

  return isModeSupported && isApplianceTypeSupported && !(isRtpOverSrtEnabled && applianceHasSrtInterface)
}

export const ApplianceSection = <T extends ApplianceSectionForm>(
  form: FormProps<T & AllowUncontrolled> & BaseFormProps,
) => {
  const {
    applianceIndex,
    namePrefix,
    onRemove,
    title,
    isApplianceOrRegionSelectable,
    onApplianceOrRegionSelected,
    enforcedPortMode,
    isModeSelectionDisabled,
    formState,
    getValues,
    isInputForm,
    adminStatus,
  } = form
  const { buildInfo } = useSelector(
    ({ buildInfoReducer }: GlobalState) => ({ buildInfo: buildInfoReducer.buildInfo }),
    equals,
  )

  const childRef = useRef<{
    onAddInterfaceButtonClicked: () => void
  }>(null)

  const {
    region: selectedRegion,
    appliance: selectedAppliance,
    ports: logicalPorts,
  }: ApplianceSectionSelectionData<EnrichedInputPort | EnrichedOutputPort> = getValues(namePrefix as any)

  const logicalPort1 = logicalPorts[0]
  const areMultipleLogicalPortsSupported = areMultipleLogicalPortsPerApplianceSupported({
    isInput: isInputForm,
    appliance: getPortAppliance(logicalPort1),
    region: selectedRegion,
    allLogicalPorts: collectPortsFromApplianceSections(getValues()),
  })
  const maxNumberOfLogicalPortsAllowed = areMultipleLogicalPortsSupported ? 2 : 1
  const canAddMoreLogicalPorts =
    !!(selectedRegion || selectedAppliance) && logicalPorts.length < maxNumberOfLogicalPortsAllowed
  // Don't allow manually (using the button) adding logical ports for SRT outputs since it is handled automatically
  const canAddMoreLogicalPortsManually =
    canAddMoreLogicalPorts && (isInputForm || logicalPorts.every((lp) => lp.mode !== IpPortMode.srt))

  const onAddLogicalPortRequested = () => {
    if (!canAddMoreLogicalPorts) return
    childRef.current?.onAddInterfaceButtonClicked()
  }

  return (
    <Paper
      id={`applianceSectionContainer-${applianceIndex}`}
      title={title}
      className={cn('outlined', formState.errors.ports && 'error')}
      actionsPane={[
        ...[
          <Button
            disabled={!canAddMoreLogicalPortsManually}
            data-test-id={`add-interface-${applianceIndex}`}
            variant="contained"
            color="secondary"
            onClick={onAddLogicalPortRequested}
          >
            Add interface
          </Button>,
        ],
        ...(onRemove
          ? [
              <Button
                key={'remote-input-appliance'}
                variant="outlined"
                color="primary"
                onClick={() => onRemove(applianceIndex)}
              >
                {` Remove ${isInputForm ? 'input' : 'output'} appliance `}
              </Button>,
            ]
          : []),
      ]}
    >
      <Paper>
        <GridItem>
          <AutoComplete<ApplianceOrRegion>
            placeholder={`${isInputForm ? 'Input' : 'Output'} appliance or region`}
            groupBy={(option) => (isAppliance(option) ? 'Appliance' : 'Region')}
            isClearable={true}
            initialValue={
              isRegionalPort(logicalPort1) ? logicalPort1.region! : selectedRegion ?? selectedAppliance ?? null
            }
            onValueSelected={(selected: ApplianceOrRegion | null) => {
              const didReselectCurrentValue =
                (selectedRegion && selected?.id === selectedRegion.id) ||
                (selectedAppliance && selected?.id === selectedAppliance.id) ||
                (!selected && !selectedRegion && !selectedAppliance)
              if (didReselectCurrentValue) return
              onApplianceOrRegionSelected(selected)
            }}
            getOptionLabel={(value: ApplianceOrRegion) => {
              if (!isAppliance(value)) return value.name
              const appliance = value as EnrichedApplianceWithOwner
              const applianceSoftwareOutdatedString =
                appliance.version && getApplianceSoftwareString(appliance.version, buildInfo)
              return `${appliance.name}${applianceSoftwareOutdatedString ? ` ${applianceSoftwareOutdatedString}` : ''}`
            }}
            renderOption={({ key, ...props }, option) => {
              const applianceSoftwareOutdatedString =
                isAppliance(option) && option.version && getApplianceSoftwareString(option.version, buildInfo)
              const type = isAppliance(option) ? getProductName(option.type) : null
              return (
                <li key={key} {...props}>
                  <Box display="flex" alignItems="center">
                    {isAppliance(option) && option.health && (
                      <div style={{ marginRight: 12 }}>
                        <ApplianceIndicatorIcon
                          status={option.health}
                          softwareMismatch={!!applianceSoftwareOutdatedString}
                        />
                      </div>
                    )}
                    <Box display="block">
                      <Typography component="div" variant="body2" color="textPrimary">
                        {option.name}
                      </Typography>
                      {type && (
                        <Typography component="div" variant="caption" color="textSecondary">
                          {type}
                        </Typography>
                      )}
                      {applianceSoftwareOutdatedString && (
                        <Typography component="div" variant="body2" color="warning.light">
                          {applianceSoftwareOutdatedString}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </li>
              )
            }}
            isOptionDisabled={(option) => !isApplianceOrRegionSelectable(option)}
            api={async (
              params: PaginatedRequestParams<any>,
            ): Promise<ListResult<EnrichedApplianceWithOwner | Region>> => {
              // TODO: Remove usage of static/compiled applianceTypes and add support for filtering on input/output capabilities in listAppliances instead.
              const hasInputCapabilities = (type: ApplianceType) =>
                applianceTypes[type](REACT_APP_EDGE_PRODUCT).input !== undefined
              const hasOutputCapabilities = (type: ApplianceType) =>
                applianceTypes[type](REACT_APP_EDGE_PRODUCT).output !== undefined
              const onlyVideoRegions = true
              const regions = await Api.regionApi.getRegions(params, onlyVideoRegions)
              const appliances = await Api.appliancesApi.getAppliances({
                ...params,
                types: Object.values(ApplianceType)
                  .filter(isInputForm ? hasInputCapabilities : hasOutputCapabilities)
                  .join(','),
              })
              return {
                items: [...regions.items, ...appliances.items],
                total: regions.total + appliances.total,
                skip: Number(params.pageNumber) * Number(params.rowsPerPage),
                limit: Number(params.rowsPerPage),
              }
            }}
            dataTestId={`${applianceIndex}-appliance`}
          />
        </GridItem>

        {selectedRegion && (
          <RegionalInterfaceSection
            {...form}
            selectedRegion={selectedRegion}
            enforcedPortMode={enforcedPortMode}
            isModeSelectionDisabled={isModeSelectionDisabled}
            logicalPorts={logicalPorts}
            myRef={childRef}
            onAddLogicalPortRequested={onAddLogicalPortRequested}
          />
        )}

        {!selectedRegion && selectedAppliance && (
          <ApplianceInterfaceSection
            {...form}
            adminStatus={adminStatus}
            selectedAppliance={selectedAppliance}
            logicalPorts={logicalPorts}
            enforcedPortMode={enforcedPortMode}
            isModeSelectionDisabled={isModeSelectionDisabled}
            myRef={childRef}
            onAddLogicalPortRequested={onAddLogicalPortRequested}
          />
        )}
      </Paper>
    </Paper>
  )
}
