import { useEffect, useCallback, useState, useRef } from 'react'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import upperFirst from 'lodash/upperFirst'
import debounce from 'lodash/debounce'

import { ListResult } from 'common/api/v1/types'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import { usePageParams } from '../../../../utils'

type AutocompleteProps<TEntity> = {
  api: (params: PaginatedRequestParams<any>) => Promise<ListResult<TEntity>>
  getOptionLabel: (option: TEntity) => string
  getOptionValue: (option: TEntity) => any
}

type Props = {
  paramName: string
  label: string
  autocomplete: AutocompleteProps<any>
}

const FilterAutocomplete = <TEntity,>(props: Props) => {
  const { paramName, label, autocomplete } = props
  const { api, getOptionValue, ...restProps } = autocomplete

  const initialized = useRef(false)
  const [params, setPageParams] = usePageParams()
  const [value, setValue] = useState<TEntity | null>(null)
  const [inputValue, setInputValue] = useState(params[paramName] || '')
  const [options, setOptions] = useState<TEntity[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [apiSearchFilter, setApiSearchFilter] = useState<string | undefined>(undefined)

  const fetch = async (filter: string | undefined) => {
    const { items } = await api({ pageNumber: '0', rowsPerPage: '30', filter })
    setOptions(items)
    setIsLoading(false)

    // We only know the value of the preselected option according to 'params',
    // must fetch options, find preselected option among them, then set it as current Autocomplete value
    if (!initialized.current) {
      setValue(items.find((v) => getOptionValue(v) === params[paramName]) || null)
    }

    initialized.current = true
  }

  const debouncedFetch = useCallback(debounce(fetch, 500), [])

  useEffect(() => {
    setOptions([])
    setIsLoading(true)

    if (initialized.current) {
      debouncedFetch(apiSearchFilter)
    } else {
      fetch(apiSearchFilter)
    }
  }, [apiSearchFilter])

  useEffect(() => {
    if (initialized.current) {
      const paramValue = value ? getOptionValue(value) : undefined
      setPageParams({ [paramName]: paramValue, pageNumber: '0' })
    }
  }, [value])

  return (
    <FormControl id={`autocomplete-${paramName}`} style={{ minWidth: '240px' }}>
      <Autocomplete
        value={value}
        inputValue={inputValue}
        options={options}
        loading={isLoading}
        fullWidth
        onChange={(_: any, newOption: TEntity | null) => {
          setValue(newOption)
        }}
        onInputChange={(_: any, newInputValue: string) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...params}
            name={paramName}
            label={label || upperFirst(paramName)}
            variant="outlined"
            onChange={(e) => {
              setApiSearchFilter(e.target.value)
            }}
            slotProps={{
              htmlInput: { ...params.inputProps, autoComplete: 'off' },
            }}
          />
        )}
        {...restProps}
      />
    </FormControl>
  )
}

export default FilterAutocomplete
