import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { ListResult, Region, RegionSortableField } from 'common/api/v1/types'
import { ThunkApi } from '../../store'
import { withDefaultPagination } from '../../utils/index'
import type { EnrichedRegion, PaginatedRequestParams } from '../../api/nm-types'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { RegionDetails } from '../../api/region/api'

export const getRegions = createAsyncThunk<
  ListResult<EnrichedRegion>,
  Partial<PaginatedRequestParams<RegionSortableField>>,
  ThunkApi
>('regions/getRegions', async (params, { extra: { api } }) => {
  return api.regionApi.getRegions(withDefaultPagination(params))
})

export const getRegionDetails = createAsyncThunk<RegionDetails, Region['id'], ThunkApi>(
  'regions/getRegionDetails',
  async (id, { dispatch, extra: { api } }) => {
    try {
      const region = await api.regionApi.getRegion(id)
      const details: Pick<RegionDetails, 'videoScale' | 'thumbScale'> = await api.regionApi
        .getRegionDetails(id, region.external)
        .catch((error) => {
          dispatch(enqueueErrorSnackbar({ error, operation: 'fetch region details' }))
          return { videoScale: { currentReplicas: 'N/A' }, thumbScale: { currentReplicas: 'N/A' } }
        })
      return { region, ...details }
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch region' }))
      throw err
    }
  },
)

export const updateRegion = createAsyncThunk<
  void,
  { regionId: string; regionName: string; videoScaleCount?: number; thumbScaleCount?: number },
  ThunkApi
>('regions/updateRegion', async (params, { dispatch, extra: { routes, api, navigate } }) => {
  try {
    if (params.videoScaleCount !== undefined) await api.regionApi.setVideoScale(params.regionId, params.videoScaleCount)
    if (params.thumbScaleCount !== undefined) await api.regionApi.setThumbScale(params.regionId, params.thumbScaleCount)
    navigate()(routes.regions())
    dispatch(enqueueSuccessSnackbar(`Updated region: ${params.regionName}`))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update region' }))
    throw err
  }
})

export const setDefaultRegion = createAsyncThunk<
  Pick<Region, 'id' | 'default_region'>,
  { regionId: Region['id']; regionName: Region['name'] },
  ThunkApi
>('regions/setDefaultRegion', async (params, { dispatch, extra: { api } }) => {
  try {
    const res = await api.regionApi.setDefaultRegion(params.regionId)
    dispatch(enqueueSuccessSnackbar(`Updated default region: ${params.regionName}`))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update default region' }))
    throw err
  }
})

export const deleteRegion = createAsyncThunk<void, Region, ThunkApi>(
  'regions/deleteRegion',
  async (region, { dispatch, extra: { api } }) => {
    try {
      await api.regionApi.deleteRegion(region.id)
      dispatch(enqueueSuccessSnackbar(`Deleted region: ${region.name}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete region' }))
      throw err
    }
  },
)

export const clearRegions = createAction('regions/clearRegions')
export const clearRegion = createAction('kubernetes/clearRegion')
