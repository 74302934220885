import AlignHorizontalLeft from '@mui/icons-material/AlignHorizontalLeft'
import AlignHorizontalRight from '@mui/icons-material/AlignHorizontalRight'
import VerticalAlignBottom from '@mui/icons-material/VerticalAlignBottom'
import VerticalAlignTop from '@mui/icons-material/VerticalAlignTop'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { OverlayComponent } from 'common/api/v1/types'

export const CoordinateControls = ({
  component,
  index,
  updateComponent,
}: {
  component: OverlayComponent
  index: number
  updateComponent: (index: number, component: OverlayComponent) => void
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="x-pos-label">X Offset</InputLabel>
        <OutlinedInput
          id="x-pos"
          label="X Offset"
          endAdornment={<InputAdornment position="end">px</InputAdornment>}
          value={component.x.value}
          onChange={(event) =>
            updateComponent(index, { ...component, x: { ...component.x, value: parseInt(event.target.value) || 0 } })
          }
        />
      </FormControl>

      <ToggleButtonGroup
        exclusive
        value={component.x.baseline}
        onChange={(_, value) => {
          if (!value) return
          updateComponent(index, { ...component, x: { ...component.x, baseline: value } })
        }}
      >
        <ToggleButton value="start">
          <AlignHorizontalLeft />
        </ToggleButton>
        <ToggleButton value="end">
          <AlignHorizontalRight />
        </ToggleButton>
      </ToggleButtonGroup>

      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="y-pos-label">Y Offset</InputLabel>
        <OutlinedInput
          id="y-pos"
          label="Y Offset"
          endAdornment={<InputAdornment position="end">px</InputAdornment>}
          value={component.y.value}
          onChange={(event) =>
            updateComponent(index, { ...component, y: { ...component.y, value: parseInt(event.target.value) || 0 } })
          }
        />
      </FormControl>

      <ToggleButtonGroup
        exclusive
        value={component.y.baseline}
        onChange={(_, value) => {
          if (!value) return
          updateComponent(index, { ...component, y: { ...component.y, baseline: value } })
        }}
      >
        <ToggleButton value="start">
          <VerticalAlignTop />
        </ToggleButton>
        <ToggleButton value="end">
          <VerticalAlignBottom />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
