import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MoreVert from '@mui/icons-material/MoreVert'
import QRCode from 'react-qr-code'

import { Api, type AppDispatch } from '../../store'
import { fetchCurrentUser, saveUserSettings } from '../../redux/actions/userActions'
import { enqueueErrorSnackbar, enqueueSnackbar, enqueueSuccessSnackbar } from '../../redux/actions/notificationActions'
import { styles } from '../../Common'
import Wrapper from '../common/Wrapper'
import { ButtonsPane, Paper, SafeRouting } from '../common/Form'
import DataSet from '../common/DataSet'
import { useUser } from '../../utils'
import Checkbox from '@mui/material/Checkbox'
import { Role, User, UserSettings } from 'common/api/v1/types'
import { clone } from 'common/util'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { APITokensTable } from './APITokens'

const MFADataValue = (isTOTPEnabled: boolean | undefined, onUpdateUri: (uri: string) => void) => {
  const dispatch = useDispatch<AppDispatch>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleClickMFAMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMFAShow = () => {
    Api.userApi
      .configureTOTP()
      .then((res) => onUpdateUri(res.uri))
      .catch((error) => dispatch(enqueueErrorSnackbar({ error, operation: 'configure MFA' })))
    setAnchorEl(null)
  }

  const handleMFADelete = () => {
    Api.userApi
      .deleteTOTP()
      .then(() => {
        dispatch(enqueueSuccessSnackbar('Deleted MFA'))
        dispatch(fetchCurrentUser())
        setAnchorEl(null)
        onUpdateUri('')
      })
      .catch((error) => dispatch(enqueueErrorSnackbar({ error, operation: 'delete MFA' })))
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {isTOTPEnabled ? 'Configured' : 'Not configured'}
      <IconButton onClick={handleClickMFAMenu} sx={{ marginTop: -1, marginLeft: 3 }}>
        <MoreVert />
      </IconButton>
      <Menu open={Boolean(anchorEl)} keepMounted anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleMFAShow}>Show</MenuItem>
        {isTOTPEnabled ? (
          <MenuItem onClick={handleMFADelete} sx={styles.error}>
            Delete
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  )
}

const MFAPane = ({ uri, onClose }: { uri: string; onClose: () => void }) => {
  const dispatch = useDispatch<AppDispatch>()
  const [token, setToken] = useState('')
  const secret = useMemo(() => {
    try {
      return new URL(uri).searchParams.get('secret') ?? ''
    } catch (_e) {
      return ''
    }
  }, [uri])

  const handleSave = () => {
    Api.userApi
      .verifyTOTP(token)
      .then((isValid) => {
        if (isValid) {
          dispatch(enqueueSuccessSnackbar('Configured MFA'))
          dispatch(fetchCurrentUser())
          onClose()
        } else {
          dispatch(
            enqueueSnackbar({
              message: 'Unable to verify the MFA code',
              type: 'basic',
              options: { variant: 'error' },
            }),
          )
        }
      })
      .catch((error) => dispatch(enqueueErrorSnackbar({ error, operation: 'verify MFA code' })))
  }

  return (
    <Paper title="Multi-factor Authentication" actionsPane={[]}>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 3, gap: 3 }}>
        <Typography>1. Scan the QR code with your authenticator app</Typography>
        <Box sx={{ background: 'white', padding: '16px', maxWidth: 200 }}>
          <QRCode
            size={200}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={uri}
            viewBox={'0 0 200 200'}
          />
        </Box>
        {secret ? (
          <Typography variant="caption">Alternatively, you can enter a key manually: {secret}</Typography>
        ) : null}
        <Typography>2. Verify the MFA code from your authenticator app</Typography>
        <TextField
          onChange={(e) => setToken(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSave()
              e.preventDefault()
            }
          }}
          id="verify-mfacode"
          label="Verify the MFA code"
          autoComplete="one-time-code"
          sx={{ maxWidth: 200 }}
        />
        <ButtonsPane
          main={{}}
          secondary={{
            Cancel: { onClick: onClose },
            Save: { primary: true, onClick: handleSave },
          }}
        />
      </Box>
    </Paper>
  )
}

const UserSettingsView = ({ userId, initialSettings }: { userId: User['id']; initialSettings: UserSettings }) => {
  const dispatch = useDispatch<AppDispatch>()
  const [settings, setSettings] = useState<UserSettings>(clone(initialSettings))

  const onSave = () => void dispatch(saveUserSettings({ userId, settings }))
  return (
    <Paper
      title="Preferences"
      actionsPane={[
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>,
      ]}
    >
      <SafeRouting enabled={settings.alarm.notificationsEnabled !== initialSettings.alarm.notificationsEnabled} />
      <Grid item>
        <FormControlLabel
          label="Show alarm notifications"
          control={
            <Checkbox
              checked={settings.alarm.notificationsEnabled}
              onChange={() =>
                setSettings((prevState) => ({
                  ...prevState,
                  alarm: {
                    ...prevState.alarm,
                    notificationsEnabled: !prevState.alarm.notificationsEnabled,
                  },
                }))
              }
            />
          }
        />
      </Grid>
    </Paper>
  )
}

type ProfileProps = {
  isStreamManager?: true | undefined // true for Stream Manager
}

export const Profile = ({ isStreamManager }: ProfileProps) => {
  const user = useUser()
  const [uri, setUri] = useState('')

  const onUpdateUri = (uri?: string) => {
    setUri(uri ?? '')
  }

  const meta = {
    Name: user.username,
    Role: user.role,
    Group: user._group.name,
    MFA: MFADataValue(user.mfa?.totp?.enabled, onUpdateUri),
  }

  return (
    <Wrapper name="Profile" isStreamManager={isStreamManager}>
      <Paper title="User data" actionsPane={[]}>
        <DataSet values={meta} />
      </Paper>
      {uri ? <MFAPane uri={uri} onClose={() => setUri('')} /> : null}

      {user.role === Role.super && <APITokensTable />}

      {user.settings && <UserSettingsView userId={user.id} initialSettings={user.settings} />}
    </Wrapper>
  )
}
