import { shallowEqual, useSelector } from 'react-redux'

import { Address, HandoverMethod, Input, InputAdminStatus, OccupiedPort } from 'common/api/v1/types'
import { GeneratorInputPort } from 'common/api/v1/types'
import { defaultResolution, defaultFrameRate, defaultScanMode, defaultTimestampResolution } from 'common/api/defaults'

import { Select, TextInput } from '../../../../common/Form'
import { Paper } from '../../../../common/Form'
import { Checkbox } from '../../../../common/Form'
import { GridItem } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions, PortToValidate } from '../../../../../utils'

import { RichOption } from 'src/components/common/Form/Select'
import { GlobalState } from '../../../../../store'
import { useEffect } from 'react'
import Button from '@mui/material/Button'
import { notUndefined } from 'common/api/v1/helpers'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'
import { useFieldArray, useFormContext } from 'react-hook-form'

export enum GeneratorFields {
  address = 'address',
  port = 'port',
  bitrate = 'bitrate',
  audioOnly = 'audioOnly',
  resolution = 'resolution',
  scanMode = 'scanMode',
  frameRate = 'frameRate',
  timestampResolution = 'timestampResolution',
  programs = 'programs',
}

export const generatorDefaults = () =>
  createDefaultFiledValues(Object.keys(GeneratorFields), ['audioOnly'], {
    resolution: defaultResolution,
    frameRate: defaultFrameRate,
    scanMode: defaultScanMode,
    timestampResolution: defaultTimestampResolution,
    bitrate: {
      type: 'vbr',
      bitrate: '',
    },
    programs: [],
  })

interface GeneratorFormProps {
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  handoverMethod: HandoverMethod
}

const allowedFrameRates: RichOption[] = [
  {
    name: '30',
    value: '30',
  },
  {
    name: '60',
    value: '60',
  },
  {
    name: '25',
    value: '25',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '29.97',
    value: '29.97',
  },
  {
    name: '59.94',
    value: '59.94',
  },
]

const allowedResolutions: RichOption[] = [
  {
    name: '1280x720',
    value: '1280x720',
  },
  {
    name: '1920x1080',
    value: '1920x1080',
  },
]

const allowedScanModes: RichOption[] = [
  {
    name: 'progressive',
    value: 'progressive',
  },
  {
    name: 'interlaced - Top Frame First (TFF)',
    value: 'interlaced_tff',
  },
  {
    name: 'interlaced - Bottom Frame First (BFF)',
    value: 'interlaced_bff',
  },
]

const allowedTimestampResolutions: RichOption[] = [
  {
    name: 'seconds',
    value: 'seconds',
  },
  {
    name: 'milliseconds',
    value: 'milliseconds',
  },
]

const bitrateTypes: RichOption[] = [
  {
    name: 'VBR – Variable bitrate',
    value: 'vbr',
  },
  {
    name: 'CBR – Constant bitrate',
    value: 'cbr',
  },
]

const allowedBitrates: RichOption[] = [
  {
    name: '5 Mbps',
    value: 5_000_000,
  },
  {
    name: '10 Mbps',
    value: 10_000_000,
  },
  {
    name: '15 Mbps',
    value: 15_000_000,
  },
  {
    name: '20 Mbps',
    value: 20_000_000,
  },
  {
    name: '30 Mbps',
    value: 30_000_000,
  },
  {
    name: '50 Mbps',
    value: 50_000_000,
  },
  {
    name: '100 Mbps',
    value: 100_000_000,
  },
]

const INTERNAL_PORT_RANGE_START = 30000

function nextFreePid(startingPid: number, occupiedPids: Set<number>) {
  const MAX = 8190 // 8191 (0x1FFF) is Null packets
  const RESERVED = [8187]
  for (let pid = startingPid; pid < MAX; pid++) {
    if (occupiedPids.has(pid)) continue
    if (RESERVED.includes(pid)) continue
    return pid
  }
  return undefined
}

function allPidsExcept(
  programs: Required<GeneratorInputPort>['programs'],
  programIndexToExclude: number,
  typeToExclude: 'audio' | 'video',
) {
  const otherPids = new Set<number>()
  for (const p of programs) {
    if (p === programs[programIndexToExclude]) {
      if (typeToExclude === 'audio') {
        if (p.video) otherPids.add(p.video.pid)
      } else {
        otherPids.add(p.audio.pid)
      }
    } else {
      otherPids.add(p.audio.pid)
      if (p.video) otherPids.add(p.video.pid)
    }
  }
  return otherPids
}

const GeneratorForm = ({ addresses, namePrefix, occupiedPorts, adminStatus, handoverMethod }: GeneratorFormProps) => {
  const { getValues, setValue, watch } = useFormContext<Input & AllowUncontrolled>()

  const audioOnlyKey = `${namePrefix}.${GeneratorFields.audioOnly}`
  const bitrateControlModeKey = `${namePrefix}.${GeneratorFields.bitrate}.type`

  // Watch for changes in fields controlling dynamic fields
  watch(audioOnlyKey)
  watch(bitrateControlModeKey)

  const { devMode } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ devMode: settingsReducer.devMode }),
    shallowEqual,
  )
  const addressSelector = `${namePrefix}.${GeneratorFields.address}`
  const portSelector = `${namePrefix}.${GeneratorFields.port}`
  const address = getValues(addressSelector)
  const port = getValues(portSelector)
  const isCBR = getValues(`${namePrefix}.${GeneratorFields.bitrate}.type`) === 'cbr'
  const generator = getValues(namePrefix) as GeneratorInputPort
  const isAudioOnly = !!getValues(audioOnlyKey)
  const programs = generator.programs ?? []

  const {
    fields: programFields,
    remove: removeProgramField,
    append: appendProgramField,
  } = useFieldArray({
    name: `${namePrefix}.${GeneratorFields.programs}`,
  })

  const addProgram = () => {
    const nextProgramIndex = programs.length
    const pidStartRange = (nextProgramIndex + 1) * 100
    const occupiedPids = new Set(programs.flatMap((pr) => [pr.audio.pid, pr.video?.pid]).filter(notUndefined))
    const nextAudioPid = nextFreePid(pidStartRange, occupiedPids)
    if (nextAudioPid) occupiedPids.add(nextAudioPid)
    const nextVideoPid = nextFreePid(pidStartRange, occupiedPids)
    const program = {
      title: `Program ${nextProgramIndex + 1}`,
      audio: { pid: (nextAudioPid ?? '') as any },
      video: { pid: (nextVideoPid ?? '') as any },
    }
    appendProgramField(program)
  }

  useEffect(() => {
    if (programs.length === 0) {
      addProgram()
    }
  })

  // Newer generators don't allow user to select address and port - they are always created on lo0
  const isOldGeneratorWithCustomAddressAndPort =
    handoverMethod === HandoverMethod.udp && port && port < INTERNAL_PORT_RANGE_START
  useEffect(() => {
    if (address !== '127.0.0.1' && isOldGeneratorWithCustomAddressAndPort) {
      setValue(addressSelector, '127.0.0.1')
    }
  }, [setValue, addressSelector, address])

  // if VBR selected reset bitrate to default
  useEffect(() => {
    const bitrateField = `${namePrefix}.${GeneratorFields.bitrate}.bitrate`
    const bitrateValue = getValues(bitrateField)
    if (!isCBR && bitrateValue) {
      setValue(bitrateField, '')
    }
  }, [getValues, setValue, namePrefix, isCBR])

  const portInfo: PortToValidate = {
    isInput: true,
    isPortDisabled: adminStatus === InputAdminStatus.off,
    mode: port.mode,
    existingLogicalPortsOnSameNic: occupiedPorts,
  }

  return (
    <>
      {isOldGeneratorWithCustomAddressAndPort && (
        <>
          <Select
            name={addressSelector}
            label="Address"
            required
            options={makeAddressOptions(getValues(addressSelector), addresses).filter((a) => a.value === '127.0.0.1')}
            disabled={true}
            newLine
            validators={{
              addressIn: { addresses },
            }}
          />
          <TextInput
            name={`${namePrefix}.${GeneratorFields.port}`}
            label="Port"
            type="number"
            disabled={true}
            noNegative
            tooltip="It is no longer possible to choose/edit the port for generator inputs. For new generator inputs, this port will be automatically assigned and not visible in Network Manager."
            validators={{
              port: { disallowInternal: false, isUdp: true },
              isPortAvailable: portInfo,
            }}
          />
        </>
      )}

      <Checkbox name={audioOnlyKey} label="Audio only" />

      <Select
        name={`${namePrefix}.${GeneratorFields.resolution}`}
        label="Resolution"
        options={allowedResolutions}
        newLine
        disabled={isAudioOnly}
      />
      {devMode && (
        <Select
          name={`${namePrefix}.${GeneratorFields.scanMode}`}
          label="Scan mode"
          options={allowedScanModes}
          newLine
          disabled={isAudioOnly}
        />
      )}
      <Select
        name={`${namePrefix}.${GeneratorFields.frameRate}`}
        label="Frame rate"
        options={allowedFrameRates}
        newLine
        disabled={isAudioOnly}
      />
      <Select
        name={`${namePrefix}.${GeneratorFields.timestampResolution}`}
        label="Timestamp resolution"
        options={allowedTimestampResolutions}
        newLine
        disabled={isAudioOnly}
      />
      <Select
        name={bitrateControlModeKey}
        label="Bitrate control mode (VBR/CBR)"
        options={bitrateTypes}
        newLine
        disabled={isAudioOnly}
      />
      <Select
        name={`${namePrefix}.${GeneratorFields.bitrate}.bitrate`}
        label="Bitrate"
        options={allowedBitrates}
        disabled={!isCBR}
        tooltip="Select the bitrate when using CBR encoding"
      />

      <Paper
        title={'MPEG-TS Programs'}
        className="outlined"
        actionsPane={[
          <Button sx={{ marginBottom: 2 }} variant="contained" color="secondary" onClick={addProgram}>
            Add program
          </Button>,
        ]}
      >
        {programFields.map((field, index) => {
          return (
            <Paper key={field.id} title={`Program #${index + 1}`}>
              <TextInput
                label="Title"
                required
                name={`${namePrefix}.${GeneratorFields.programs}.${index}.title`}
                newLine
                validators={{
                  notOneOf: {
                    invalidValues: new Set(
                      programs.filter((p) => p.title !== programs[index].title).map((p) => p.title),
                    ),
                    errorMsg: `Title already specified`,
                  },
                }}
              />
              <TextInput
                type="number"
                label="Audio Pid"
                required
                name={`${namePrefix}.${GeneratorFields.programs}.${index}.audio.pid`}
                newLine
                validators={{
                  pid: {},
                  notOneOf: {
                    invalidValues: allPidsExcept(programs, index, 'audio'),
                    errorMsg: `PID already specified`,
                  },
                }}
              />
              <TextInput
                type="number"
                label="Video Pid"
                required
                name={`${namePrefix}.${GeneratorFields.programs}.${index}.video.pid`}
                validators={{
                  pid: {},
                  notOneOf: {
                    invalidValues: allPidsExcept(programs, index, 'video'),
                    errorMsg: `PID already specified`,
                  },
                }}
              />
              <GridItem newLine>
                <Button
                  style={{ marginTop: 12 }}
                  variant="outlined"
                  color="primary"
                  disabled={programs.length < 2}
                  onClick={() => removeProgramField(index)}
                >
                  Remove Program
                </Button>
              </GridItem>
            </Paper>
          )
        })}
      </Paper>
    </>
  )
}

export default GeneratorForm
