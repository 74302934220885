import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import Grid from '@mui/material/Grid'

import {
  Appliance,
  AudioStream,
  CoaxOutputPort,
  CoaxPortMode,
  ComprimatoPortMode,
  DelayMode,
  EncoderSettings,
  GeneralEncoderSettings,
  IpOutputPort,
  IpPortMode,
  MatroxPortMode,
  Output,
  OutputAdminStatus,
  OutputInit,
  OutputPort,
  OutputRedundancyMode,
  PhysicalPort,
  RawVideo,
  SrtListenerOutputPort,
  VideoCodec,
  ZixiLink,
} from 'common/api/v1/types'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { formTransform, useConfirmationDialog } from '../../../utils'
import { clearOutputFormErrors, createOutput, updateOutput } from '../../../redux/actions/outputsActions'

import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'
import { getIpPortFormFields } from './PortForm/IpPortForm'

import OutputForm, { initialOutputLogicalPort } from './OutputForm'
import { SrtFields } from './PortForm/IpPortForm/SrtForm'
import { getCoaxPortFormFields } from './PortForm/CoaxPortForm/CoaxPortForm'
import { ZixiFields } from './PortForm/IpPortForm/ZixiForm'
import { LinkFields, linkSetDefault } from './PortForm/IpPortForm/ZixiForm/LinksArray'
import { getSettings } from '../../../redux/actions/settingsActions'
import {
  EnrichedOutputPort,
  EnrichedOutputWithPorts,
  EnrichedPhysicalPort,
  SrtBondingMode,
} from '../../../api/nm-types'
import {
  APPLIANCE_SECTION_FORM_PREFIX,
  collectPortsFromApplianceSections,
  groupPortsByApplianceOrRegion,
} from '../../common/Interface/Base'
import { inputRedundancy } from 'common/utils'
import { RHF } from '../../common/Form'
import { useAsync } from 'react-async-hook'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'

export type OutputPortWithEnrichedPhysicalPortAndAppliance = OutputPort & {
  _port: EnrichedPhysicalPort & { _appliance: Appliance }
}

export type EnrichedOutputWithEnrichedPorts = EnrichedOutputWithPorts & {
  ports: OutputPortWithEnrichedPhysicalPortAndAppliance[]
}

const getInitialState = (
  selectedOutput?: EnrichedOutputWithPorts,
  defaultDelay?: number,
): EnrichedOutputWithEnrichedPorts => {
  const encoderSettings: EncoderSettings = {
    videoCodec: '' as VideoCodec,
    totalBitrate: '' as unknown as number,
    gopSizeFrames: 150,
    audioStreams: [],
    latencyMode: '',
    scalingMode: '',
    profile: '',
    pixelFormat: '',
    colorSampling: '',
    bitDepth: '' as unknown as number,
    resolution: '',
    scanRate: '',
  }
  const receiver = {
    name: '',
    delay: defaultDelay || '',
    delayMode: DelayMode.basedOnOriginTime,
    unhealthyAlarm: '',
    adminStatus: true,
    ports: [],
    upstreamAppliances: [],
    encoderSettings,
    redundancyMode:
      selectedOutput && 'redundancyMode' in selectedOutput
        ? selectedOutput.redundancyMode
        : selectedOutput?.ports[0]?.copies == 2
        ? OutputRedundancyMode.active
        : OutputRedundancyMode.none,
  }
  mergeWith(receiver, omit(selectedOutput, ['metrics', 'alarms']), (_initial, existingValueForKey, key) => {
    if ('totalBitrate' === key && existingValueForKey) {
      return existingValueForKey / 1000000
    }
    if (key === 'ports') {
      const failoverPriorities = existingValueForKey
        .map((p: any) => p.failoverPriority)
        .filter((p: any) => typeof p === 'number')
      const numDistinctFailoverPriorities = new Set(failoverPriorities).size
      return existingValueForKey.map((item: EnrichedOutputPort) =>
        mergeWith(
          initialOutputLogicalPort({
            physicalPortId: item.physicalPort,
            port: item._port,
            numDistinctFailoverPriorities,
            settings: item._port?._appliance?.settings,
          }),
          item,
          (_initial, existingValueForKey, key) => {
            if ((SrtFields.inputBw === key || SrtFields.maxBw === key) && existingValueForKey !== undefined)
              return existingValueForKey / 1000000
            if ((key === ZixiFields.linkSet1 || key === ZixiFields.linkSet2) && existingValueForKey) {
              return existingValueForKey.map((link: ZixiLink) => ({
                ...linkSetDefault,
                ...link,
                [LinkFields.rateLimit]: link.rateLimit ? link.rateLimit / 1000 : linkSetDefault.rateLimit,
              }))
            }
          },
        ),
      )
    }
    if (key === '_input') {
      return existingValueForKey
    }
    if (key === 'adminStatus') {
      return existingValueForKey === OutputAdminStatus.on
    }
  })

  const portsGroupedByApplianceOrRegion = groupPortsByApplianceOrRegion(receiver.ports)
  return {
    ...receiver,
    [APPLIANCE_SECTION_FORM_PREFIX]: portsGroupedByApplianceOrRegion,
  } as unknown as EnrichedOutputWithEnrichedPorts
}

export const Edit = () => {
  const { id: outputId } = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const {
    result: selectedOutput,
    error: fetchOutputError,
    loading: isFetchingOutput,
  } = useAsync(() => {
    return outputId
      ? Api.outputApi.getOutput(outputId).catch((err) => {
          dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch output' }))
          throw err
        })
      : Promise.resolve(undefined)
  }, [dispatch, outputId])

  useEffect(() => {
    if (!outputId) {
      dispatch(getSettings())
    }
    return () => {
      dispatch(clearOutputFormErrors())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const { settings, loading: settingsLoading } = useSelector(
    ({ settingsReducer }: GlobalState) => ({
      settings: settingsReducer.settings,
      loading: settingsReducer.loading,
    }),
    shallowEqual,
  )
  const initialState = getInitialState(selectedOutput, settings?.defaultDelay)
  const setConfirm = useConfirmationDialog()

  const onSubmit = (output: OutputInit | Output, changedPorts: boolean) => {
    if (selectedOutput) {
      const action = () => void dispatch(updateOutput({ output: output as Output, redirect: true }))
      if (selectedOutput && selectedOutput.input && changedPorts) {
        setConfirm(action, 'Current output might be in use. Are you sure you want to edit it?')
      } else {
        action()
      }
    } else {
      dispatch(createOutput({ output, redirect: true }))
    }
  }

  return (
    <Wrapper name={['Outputs', outputId ? get(selectedOutput, 'name') : 'New']}>
      <Grid container spacing={0}>
        <Pendable pending={isFetchingOutput || (!outputId && settingsLoading)}>
          {fetchOutputError ? (
            'Failed fetching output'
          ) : (
            <RHF
              component={OutputForm}
              defaultValues={initialState}
              onSubmit={(values, methods) => {
                values.ports = collectPortsFromApplianceSections(values)
                if (!values.ports.length) {
                  methods.setError('ports', { type: 'required', message: "Interface can't be blank" })
                  return
                }

                const hasDecoderSettings = values.ports.some((p) => {
                  const applianceFeatures = p._port?._appliance?.features ?? (p as any)._port?.appliance?.features
                  const modesWithDecoderSettings =
                    applianceFeatures?.output?.modes.filter((m) => !!m.decoder).map((m) => m.mode) ?? []
                  return modesWithDecoderSettings.includes(p.mode)
                })
                const changedPorts = !isEqual(initialState.ports, values.ports)
                const transformed = formTransform(values, {
                  adminStatus: { _transform: (val: boolean) => (val ? OutputAdminStatus.on : OutputAdminStatus.off) },
                  ports: {
                    [SrtFields.inputBw]: {
                      _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                    },
                    [SrtFields.maxBw]: {
                      _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                    },
                    [ZixiFields.linkSet1]: {
                      [LinkFields.rateLimit]: {
                        _transform: (limit: number | '') => (limit === '' ? undefined : limit * 1000),
                      },
                    },
                    [ZixiFields.linkSet2]: {
                      [LinkFields.rateLimit]: {
                        _transform: (limit: number | '') => (limit === '' ? undefined : limit * 1000),
                      },
                    },
                    encoderSettings: {
                      _transform: (es: Partial<GeneralEncoderSettings>) => (hasDecoderSettings ? es : undefined),
                      totalBitrate: {
                        _transform: (bitrate: number | '') => (bitrate === '' ? undefined : bitrate * 1000000),
                      },
                      audioStreams: {
                        // TODO: Codec AES3 does not support bitrate but it is a required property.
                        _transform: (audioStream: AudioStream) => ({
                          ...audioStream,
                          bitrate: audioStream.bitrate || -1,
                        }),
                      },
                    },
                    _transform: (port: Partial<OutputPort> & { _port: PhysicalPort }) => {
                      if ('encoderSettings' in port && (port.encoderSettings?.videoCodec as string) === RawVideo) {
                        delete port.encoderSettings?.totalBitrate
                      }
                      if (port.mode === ComprimatoPortMode.comprimatoNdi) {
                        return pick(port, ['mode', 'name', 'physicalPort', 'encoderSettings'])
                      }

                      if (port.mode === IpPortMode.rtp && (port.fec as string) === 'none') {
                        // eslint-disable-next-line no-param-reassign
                        delete port.fec
                      }

                      const coaxPortModes: string[] = [
                        ...Object.values(Object.assign({}, CoaxPortMode, ComprimatoPortMode, MatroxPortMode)),
                      ]
                      const isCoaxPort = port.mode && coaxPortModes.includes(port.mode)

                      const fields = pick(
                        port,
                        isCoaxPort
                          ? getCoaxPortFormFields(port as CoaxOutputPort)
                          : getIpPortFormFields(port as IpOutputPort),
                      )
                      return omit(fields, ['region.allocatedAppliance'])
                    },
                  },
                  _transform: (output: Partial<Output>) => {
                    const o = output as EnrichedOutputWithPorts
                    if (o.redundancyMode != OutputRedundancyMode.none) {
                      const input = o._input
                      const isInputRedundant = !!input && inputRedundancy(input)
                      if (!isInputRedundant) {
                        o.redundancyMode = OutputRedundancyMode.none
                      }
                    }
                    const withoutUnderscoreProps = omitBy(o, (_value: any, key: string) =>
                      key.startsWith('_'),
                    ) as OutputInit
                    const isSrtOutput = withoutUnderscoreProps.ports.find((p) => p.mode === IpPortMode.srt) // no support for mixed modes yet
                    if (isSrtOutput) {
                      let failoverPriority: number | undefined = undefined
                      const bondingMode: SrtBondingMode = (withoutUnderscoreProps.ports[0] as any).bondingMode
                      if (bondingMode !== SrtBondingMode.none) {
                        failoverPriority = 0
                      }
                      for (const p of withoutUnderscoreProps.ports) {
                        ;(p as SrtListenerOutputPort).failoverPriority = failoverPriority
                        if (bondingMode === SrtBondingMode.activeBackup) {
                          failoverPriority! += 1
                        }
                      }
                    }
                    return omit(withoutUnderscoreProps, ['appliance', 'object', 'group', 'health'])
                  },
                })
                if (!transformed.unhealthyAlarm) {
                  transformed.unhealthyAlarm = null
                }
                onSubmit(transformed, changedPorts)
              }}
            />
          )}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
