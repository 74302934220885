import { useEffect, useState } from 'react'
import MuiCheckbox from '@mui/material/Checkbox'

import {
  Address,
  ApplianceType,
  ApplianceVersion,
  Input,
  InputAdminStatus,
  IpPortMode,
  OccupiedPort,
  RtpInputPort,
} from 'common/api/v1/types'
import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, isCoreNode, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { supportsFec } from 'common/versions'
import { WhitelistCidrBlockAutoComplete } from '../../../../common/WhitelistCidrBlockAutoComplete'
import { SuggestedLocalPortTextField } from '../../../../common/SuggestedPortTextField'
import { useFormContext } from 'react-hook-form'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'

export enum RtpFields {
  address = 'address',
  port = 'port',
  fec = 'fec',
  multicastAddress = 'multicastAddress',
  multicastSource = 'multicastSource',
  whitelistCidrBlock = 'whitelistCidrBlock',
  failoverPriority = 'failoverPriority',
}

export const rtpDefaults = () =>
  createDefaultFiledValues(Object.keys(RtpFields), [RtpFields.fec], {
    [RtpFields.whitelistCidrBlock]: [],
  })

interface RtpFormProps {
  addresses: Array<Address>
  portIndex: number
  applianceType: ApplianceType
  applianceVersion: ApplianceVersion
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
}

const RtpForm = ({
  addresses,
  namePrefix,
  applianceType,
  applianceVersion,
  portIndex,
  occupiedPorts,
  adminStatus,
}: RtpFormProps) => {
  const { getValues, setValue, watch } = useFormContext<Input & AllowUncontrolled>()

  const isCoreAppliance = isCoreNode(applianceType)

  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2) // _applianceSection.x.ports.0 --> _applianceSection.x.ports
  const ports: RtpInputPort[] = getValues(portsNamePrefix)
  const rtpInputPort: RtpInputPort = getValues(namePrefix)

  const fecSelector = `${namePrefix}.${RtpFields.fec}`
  const isRtcpEnabled = false // TODO: Make configurable when RTP RTCP is supported by ristserver
  const isFecEnabled: boolean = getValues(fecSelector)
  const showFecCheckbox = supportsFec(applianceType, applianceVersion)
  watch(fecSelector)

  const getMulticast = () => ({
    address: rtpInputPort.multicastAddress,
    source: rtpInputPort.multicastSource,
  })
  const { address, source } = getMulticast()
  const [isMulticast, setIsMulticast] = useState(!!(address || source))
  const [multicast, setMulticast] = useState(getMulticast())

  const showDash7Checkbox = portIndex > 0
  const isDash7Redundant = typeof rtpInputPort.failoverPriority !== 'number'

  const addressSelector = `${namePrefix}.${RtpFields.address}`
  const selectedSourceAddress = getValues(addressSelector)
  const portSelector = `${namePrefix}.${RtpFields.port}`

  useEffect(() => {
    if (!isMulticast) {
      setMulticast(getMulticast())
      setValue(`${namePrefix}.${RtpFields.multicastAddress}`, '')
      setValue(`${namePrefix}.${RtpFields.multicastSource}`, '')
    } else {
      setValue(`${namePrefix}.${RtpFields.multicastAddress}`, multicast.address)
      setValue(`${namePrefix}.${RtpFields.multicastSource}`, multicast.source)
    }
  }, [isMulticast, setValue])

  return (
    <>
      {showDash7Checkbox && (
        <Checkbox
          label="Identical RTP streams (SMPTE 2022-7)"
          tooltip={`Requires that the two interfaces receive binary equal streams, e.g. from the same encoder.`}
          control={
            <MuiCheckbox
              id={`${namePrefix}.${RtpFields.failoverPriority}`}
              name={`${namePrefix}.${RtpFields.failoverPriority}`}
              checked={isDash7Redundant}
              onChange={(e) => {
                setValue(
                  portsNamePrefix,
                  ports.map((port, portIndex) => ({
                    ...port,
                    [RtpFields.failoverPriority]: e.target.checked ? undefined : portIndex,
                  })),
                )
              }}
            />
          }
        />
      )}
      <Select
        name={addressSelector}
        label="Address"
        required
        options={makeAddressOptions(selectedSourceAddress, addresses)}
        newLine
        validators={{
          addressIn: { addresses },
        }}
      />

      <SuggestedLocalPortTextField
        name={portSelector}
        label={'Port'}
        namePrefix={namePrefix}
        required={true}
        portInfo={{
          mode: IpPortMode.rtp,
          isInput: true,
          isFec: isFecEnabled,
          isRtpRtcp: isRtcpEnabled,
          isMulticast,
          existingLogicalPortsOnSameNic: occupiedPorts,
          isPortDisabled: adminStatus === InputAdminStatus.off,
        }}
      />

      {showFecCheckbox && <Checkbox name={fecSelector} label="Use Forward Error Correction?" />}
      <Checkbox
        control={
          <MuiCheckbox
            checked={isMulticast}
            onChange={(e) => setIsMulticast(e.target.checked)}
            id={`${namePrefix}.multicast`}
            name={`${namePrefix}.multicast`}
          />
        }
        label="Is this port multicast?"
        newLine
      />
      {isMulticast && (
        <>
          <TextInput
            name={`${namePrefix}.${RtpFields.multicastAddress}`}
            label="Multicast address"
            validators={{
              multicastAddress: {},
            }}
            newLine
          />
          <TextInput
            name={`${namePrefix}.${RtpFields.multicastSource}`}
            label="Source specific multicast address"
            validators={{
              ip: {},
            }}
          />
        </>
      )}
      {!isVaApplianceType(applianceType) && (
        <WhitelistCidrBlockAutoComplete
          name={`${namePrefix}.${RtpFields.whitelistCidrBlock}`}
          required={isCoreAppliance}
        />
      )}
    </>
  )
}

export default RtpForm
