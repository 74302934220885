import { ApplianceType } from 'common/api/v1/types'
import { useRoutes } from '../../../store'
import { GridItem, Paper } from '../../common/Form'
import DataSet from '../../common/DataSet'
import { Link } from '../../common/Link'
import type { RegionDetails } from '../../../api/region/api'

export interface MetaProps {
  regionDetails: RegionDetails
}

export const Meta = ({ regionDetails }: MetaProps) => {
  const routes = useRoutes()
  const name = regionDetails.region.name
  const meta = {
    Name: name,
    Type: regionDetails.region._externalText,
    Default: regionDetails.region.default_region ? 'True' : 'False',
    'Appliances (Total)': (
      <Link to={routes.appliances({ regions: [name] })} underline="hover">
        {regionDetails.region.appliancesTotal}
      </Link>
    ),
    'Video Appliances': (
      <Link to={routes.appliances({ regions: [name], types: [ApplianceType.core] })} underline="hover">
        {regionDetails.videoScale.currentReplicas}
      </Link>
    ),
    'Thumb Appliances': (
      <Link to={routes.appliances({ regions: [name], types: [ApplianceType.thumb] })} underline="hover">
        {regionDetails.thumbScale.currentReplicas}
      </Link>
    ),
  }

  return (
    <Paper title="Metadata" collapsible actionsPane={[]}>
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
    </Paper>
  )
}
