import React, { KeyboardEvent, useContext } from 'react'
import { OnSubmit } from './RHF'

const Form = ({
  id,
  noValidate,
  children,
  disableSubmitOnEnterKey,
}: {
  id: string
  noValidate?: boolean
  children: React.ReactNode
  disableSubmitOnEnterKey?: boolean
}) => {
  const ctx = useContext(OnSubmit)

  const handleKeyDown = (e: KeyboardEvent<any>) => {
    if (!!disableSubmitOnEnterKey && e.key === 'Enter') {
      // Prevent Enter key from submitting the form
      e.preventDefault()
    }
  }

  return (
    <form id={id} noValidate={noValidate} onSubmit={ctx?.onSubmit} onKeyDown={handleKeyDown}>
      {children}
    </form>
  )
}

export default Form
