import { FC, ReactNode } from 'react'
import omit from 'lodash/fp/omit'
import Box from '@mui/material/Box'
import Grid, { GridSize } from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Tooltip from '../Tooltip'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { SxProps, Theme } from '@mui/material/styles'

const classes = {
  divider: {
    width: '100%',
    borderColor: 'transparent',
  },
  tooltip: {
    fontSize: (theme: Theme) => theme.typography.body2.fontSize,
  },
  icon: {
    marginLeft: (theme: Theme) => theme.spacing(1),
    transform: 'translateY(3px)',
  },
}

export const omitGridProps = omit(['xs', 'lg', 'xl', 'newLine', 'fullWidth', 'tooltip'])

export interface GridItemProps {
  xs?: GridSize
  lg?: GridSize
  xl?: GridSize
  newLine?: boolean
  tooltip?: string
  children?: ReactNode
  sx?: SxProps<Theme>
}

/**
 * Every field should be wrapped with this component. It provides with media query sizing, tooltip, and whether its field should always be on the new line
 * @param props
 */
const GridItem: FC<GridItemProps> = (props) => {
  return (
    <>
      {props.newLine && <Divider component="div" light sx={classes.divider} />}
      <Grid item xs={props.xs || 12} lg={props.lg || 6} xl={props.xl || 4} sx={props.sx}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {props.children}
          {!!props.tooltip && (
            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{props.tooltip}</span>}
              sx={{ tooltip: classes.tooltip }}
              placement="top"
            >
              <HelpOutline sx={classes.icon} />
            </Tooltip>
          )}
        </Box>
      </Grid>
    </>
  )
}

export default GridItem
