import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../../common/Tooltip'
import Delete from '@mui/icons-material/Delete'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Stop from '@mui/icons-material/Stop'

import { Output, OutputAdminStatus } from 'common/api/v1/types'
import { pluralize, useSettingsSelector } from '../../../utils'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { DraftActions, draftOutputs, enableOutputs } from '../../../redux/actions/outputsActions'
import {
  ListOutputFilterKey,
  makeListOutputFilter,
  mapOutputFilterToUrlParam,
  mapUrlParamToOutputFilter,
} from './listOutputFilter'
import { UrlParamFilteredSearchBar } from '../../common/Filters/FilterView/FilteredSearchBar'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'

interface CommonActionsProps {
  selected: Array<Output['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>

  outputs: Output[]
}

const BatchEnableButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedOutputs = outputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedOutputs.some(({ adminStatus }) => adminStatus === OutputAdminStatus.off)

  const onEnableClick = () => {
    dispatch(enableOutputs(selected))
    dispatch(draftOutputs({ outputs: [] }))
    setSelected([])
  }

  return (
    <Tooltip title={'Enable selected outputs'} disablePortal={false}>
      <span>
        <IconButton aria-label="Enable" disabled={!enable} onClick={onEnableClick} data-test-id="batch-enable">
          <PlayArrow />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const BatchDisableButton = ({ selected }: { selected: Array<Output['id']> }) => {
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedOutputs = outputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedOutputs.some(({ adminStatus }) => adminStatus === OutputAdminStatus.on)

  const onDisableClick = () => {
    dispatch(draftOutputs({ action: DraftActions.disable, outputs: selectedOutputs }))
  }

  return (
    <Tooltip title={'Disable selected outputs'} disablePortal={false}>
      <span>
        <IconButton aria-label="Disable" disabled={!enable} onClick={onDisableClick} data-test-id="batch-disable">
          <Stop />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ outputs, selected, setSelected }: CommonActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { settings } = useSettingsSelector()
  const expFeatures = settings?.expFeatures
  const outputsMap = outputs.reduce<{ [key: string]: Output }>((acc, item) => ({ ...acc, [item.id]: item }), {})

  const { showButtons } = selected.reduce(
    ({ showButtons }, id) => {
      const output = outputsMap[id]
      return {
        showButtons: Boolean(showButtons && output),
      }
    },
    { showButtons: true },
  )

  const onDeleteClick = () =>
    dispatch(draftOutputs({ action: DraftActions.delete, outputs: selected.map((id) => outputsMap[id]) }))

  const filters = makeListOutputFilter({
    applianceApi: Api.appliancesApi,
    groupApi: Api.groupsApi,
    inputApi: Api.inputApi,
    regionApi: Api.regionApi,
    tagApi: Api.tagApi,
    expFeatures,
  })

  return (
    <Toolbar disableGutters style={{ overflowX: 'clip' }}>
      <Box
        sx={{
          transition: (theme: Theme) =>
            theme.transitions.create('flex-shrink', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }),
        }}
        style={{
          flexShrink: selected.length > 0 ? 1 : 0,
          flexGrow: 1,
          flexBasis: '100%',
        }}
      >
        <UrlParamFilteredSearchBar
          urlParamCacheKey={'outputs'}
          mapUrlParamToFilterFn={mapUrlParamToOutputFilter}
          mapFilterToUrlParamFn={mapOutputFilterToUrlParam}
          searchTokenFilters={filters}
          rawTextFilter={filters.find((f) => f.key === ListOutputFilterKey.name)!}
        />
      </Box>

      <Box
        sx={{
          transition: (theme: Theme) =>
            // Fade out, i.e. keep visible until contents has left the screen
            theme.transitions.create('visibility', {
              delay: selected.length === 0 ? theme.transitions.duration.shortest : 0,
            }),
        }}
        style={{
          marginLeft: 16,
          flexShrink: 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          visibility: selected.length > 0 ? 'visible' : 'hidden',
        }}
      >
        <div style={{ width: 150, display: 'flex', justifyContent: 'center' }}>
          <Typography variant="subtitle1">{pluralize(selected.length, 'output')} selected</Typography>
        </div>

        {showButtons && (
          <div>
            <Tooltip title={'Delete selected outputs'} disablePortal={false}>
              <span>
                <IconButton aria-label="Delete" onClick={onDeleteClick} data-test-id="delete-selected">
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
            <BatchDisableButton selected={selected} />
            <BatchEnableButton selected={selected} setSelected={setSelected} />
          </div>
        )}
      </Box>
    </Toolbar>
  )
}

export default CommonActions
