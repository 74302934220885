import merge from 'lodash/fp/merge'
import { createTheme, ThemeOptions } from '@mui/material/styles'

import { defaultCommon, NimbraGrey } from './common'
export { NimbraBlue, NimbraGrey } from './common'

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: NimbraGrey[1000],
      paper: NimbraGrey[900],
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          backgroundColor: NimbraGrey[1000],
          color: '#ffffff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: '#ffffff',
        },
        outlinedSecondary: {
          color: '#ffffff',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // Use existing space for helper text like validation errors to prevent shifting content below field
          marginTop: 0,
          height: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          border: `1px solid ${NimbraGrey[800]}`,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${NimbraGrey[800]}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: NimbraGrey[900],
        },
      },
    },
  },
}

export const defaultThemeDark = createTheme(merge(defaultCommon, darkThemeOptions))
