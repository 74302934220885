import { useEffect } from 'react'

import {
  Address,
  ApplianceFeatures,
  ApplianceType,
  ApplianceVersion,
  IpPortMode,
  OccupiedPort,
  Output,
  OutputAdminStatus,
  SrtKeylen,
  SrtMode,
  SrtOutputPort,
  SrtRateLimiting,
} from 'common/api/v1/types'

import { Checkbox, Select, TextInput } from '../../../../common/Form'
import {
  createDefaultFiledValues,
  isCoreNode,
  makeAddressOptions,
  PortToValidate,
  usePrevious,
} from '../../../../../utils'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { WhitelistCidrBlockAutoComplete } from '../../../../common/WhitelistCidrBlockAutoComplete'
import { SuggestedLocalPortTextField } from '../../../../common/SuggestedPortTextField'
import { SrtBondingMode, srtBondingOptions } from '../../../../../api/nm-types'
import { AllowUncontrolled } from 'src/components/common/Form/RHF'
import { useFormContext } from 'react-hook-form'
import {
  areMultipleLogicalPortsPerApplianceSupported,
  collectPortsFromApplianceSections,
  getPortAppliance,
} from '../../../../common/Interface/Base'
import { supportsRtpOverSrt } from 'common/versions'
import { group } from 'common/utils'

export type SrtOutput = Output & { ports: SrtOutputPort[] }

const LATENCY_MAX = 6 * 60 * 1000
const TTL_MAX = 255
const MTU_MIN = 76

export enum SrtFields {
  srtMode = 'srtMode',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  localIp = 'localIp',
  localPort = 'localPort',
  rateLimiting = 'rateLimiting',
  maxBw = 'maxBw',
  inputBw = 'inputBw',
  oheadBw = 'oheadBw',
  latency = 'latency',
  pbkeylen = 'pbkeylen',
  passphrase = 'passphrase',
  ipttl = 'ipttl',
  mss = 'mss',
  streamId = 'streamId',
  whitelistCidrBlock = 'whitelistCidrBlock',
  bondingMode = 'bondingMode',
  failoverPriority = 'failoverPriority',
  rtp = 'rtp',
}

export const srtDefaults = (numDistinctFailoverPriorities: number) =>
  createDefaultFiledValues(Object.keys(SrtFields), [SrtFields.rtp], {
    [SrtFields.latency]: 120,
    [SrtFields.pbkeylen]: SrtKeylen.none,
    [SrtFields.oheadBw]: 25,
    [SrtFields.rateLimiting]: SrtRateLimiting.notEnforced,
    [SrtFields.whitelistCidrBlock]: [],
    [SrtFields.bondingMode]:
      numDistinctFailoverPriorities === 0
        ? SrtBondingMode.none
        : numDistinctFailoverPriorities > 1
        ? SrtBondingMode.activeBackup
        : SrtBondingMode.activeActive,
  })
export const getSrtFieldsToSave = (port: SrtOutputPort) => [
  SrtFields.srtMode,
  SrtFields.latency,
  SrtFields.rateLimiting,
  SrtFields.pbkeylen,
  SrtFields.ipttl,
  SrtFields.mss,
  SrtFields.streamId,
  SrtFields.rtp,
  ...(port.pbkeylen === SrtKeylen.none ? [] : [SrtFields.passphrase]),
  ...(function () {
    switch (port.srtMode) {
      case SrtMode.listener:
        return [SrtFields.localIp, SrtFields.localPort, SrtFields.whitelistCidrBlock, SrtFields.failoverPriority]
      case SrtMode.caller:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localPort, SrtFields.failoverPriority]
      default:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localIp, SrtFields.whitelistCidrBlock]
    }
  })(),
  ...(function () {
    switch (port.rateLimiting) {
      case SrtRateLimiting.absolute:
        return [SrtFields.maxBw]
      case SrtRateLimiting.relativeToInput:
        return [SrtFields.inputBw, SrtFields.oheadBw]
      default:
        return []
    }
  })(),
]

interface SrtFormProps {
  portIndex: number
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: OutputAdminStatus
  applianceId: string
  applianceType: ApplianceType
  applianceVersion: ApplianceVersion
  applianceFeatures: ApplianceFeatures
  onAddLogicalPortRequested: () => void
  onRemoveLogicalPortRequested: () => void
}

const SrtForm = ({
  portIndex,
  addresses,
  namePrefix,
  occupiedPorts,
  adminStatus,
  applianceId,
  applianceType,
  applianceVersion,
  applianceFeatures,
  onAddLogicalPortRequested,
  onRemoveLogicalPortRequested,
}: SrtFormProps) => {
  const { getValues, setValue, watch } = useFormContext<Output & AllowUncontrolled>()

  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2)
  const appliancePorts: SrtOutputPort[] = getValues(portsNamePrefix)
  const previousNumberOfPorts = usePrevious(appliancePorts.length)

  const firstAppliancePort = appliancePorts[0] as SrtOutputPort & {
    [SrtFields.bondingMode]: SrtBondingMode | undefined
  }
  const secondAppliancePort: SrtOutputPort | undefined = appliancePorts[1]
  const currentPort: SrtOutputPort | undefined = appliancePorts[portIndex]

  // Watch specific fields since they control conditional rendering
  watch(`${portsNamePrefix}.0.${SrtFields.rtp}`) // isRtpOverSrtEnabled
  ;[0, 1].forEach((i) => {
    const prefix = `${portsNamePrefix}.${i}`
    watch(`${prefix}.${SrtFields.srtMode}`)
    watch(`${prefix}.${SrtFields.bondingMode}`)
    watch(`${prefix}.${SrtFields.rateLimiting}`)
    watch(`${prefix}.${SrtFields.pbkeylen}`)
  })

  const isBondingEnabled =
    Boolean(firstAppliancePort.bondingMode) && firstAppliancePort.bondingMode !== SrtBondingMode.none
  const previousIsBondingEnabled = usePrevious(isBondingEnabled)

  const localAddressSelector = `${namePrefix}.${SrtFields.localIp}`
  const localPortSelector = `${namePrefix}.${SrtFields.localPort}`
  const supportedModes = applianceFeatures.output?.modes.find((m) => m.mode === IpPortMode.srt)?.subModes ?? []

  const currentPortInfo: PortToValidate = {
    isInput: false,
    isPortDisabled: adminStatus === OutputAdminStatus.off,
    mode: currentPort.mode,
    existingLogicalPortsOnSameNic: occupiedPorts,
  }

  useEffect(() => {
    // Ensure that the second srt mode mirrors the first if bonding is enabled
    if (portIndex === 1 && firstAppliancePort && secondAppliancePort) {
      if (firstAppliancePort.srtMode !== secondAppliancePort.srtMode && isBondingEnabled) {
        setValue(`${portsNamePrefix}.1.srtMode`, firstAppliancePort.srtMode, { shouldValidate: true })
      }
    }
  }, [
    portIndex,
    firstAppliancePort?.srtMode,
    secondAppliancePort?.srtMode,
    isBondingEnabled,
    setValue,
    portsNamePrefix,
  ])

  // Effect that ensures a valid bonding mode is selected
  useEffect(() => {
    if (portIndex > 0) return

    function ensureCorrectBondingMode() {
      let newBondingMode: SrtBondingMode | undefined = undefined
      if (isBondingEnabled) {
        if (firstAppliancePort.srtMode === SrtMode.rendezvous) {
          // Ensure that bonding mode is none if rendezvous is chosen
          newBondingMode = SrtBondingMode.none
        } else if (
          firstAppliancePort.srtMode === SrtMode.listener &&
          firstAppliancePort.bondingMode === SrtBondingMode.activeBackup
        ) {
          // Ensure that bonding mode is not active/backup if listener is chosen
          newBondingMode = SrtBondingMode.activeActive
        }
        if (appliancePorts.length === 1) {
          // Bonding is enabled but only one logical port
          const didDeletePort =
            previousNumberOfPorts !== undefined ? appliancePorts.length < previousNumberOfPorts : false
          if (didDeletePort) {
            // User just deleted the second logical port - disable bonding
            newBondingMode = SrtBondingMode.none
          }
        }
      }
      if (newBondingMode !== undefined && firstAppliancePort.bondingMode !== newBondingMode) {
        setValue(`${portsNamePrefix}.0.bondingMode`, newBondingMode, { shouldValidate: true })
      }
    }

    ensureCorrectBondingMode()
  }, [
    isBondingEnabled,
    portIndex,
    firstAppliancePort.srtMode,
    firstAppliancePort.bondingMode,
    appliancePorts,
    previousNumberOfPorts,
    setValue,
  ])

  // Effect that adds/removes logical ports - triggers every time bonding mode is enabled/disabled (either by user or by another effect).
  useEffect(() => {
    if (isBondingEnabled === previousIsBondingEnabled) return
    // User enabled/disabled bonding

    function ensureCorrectNumberLogicalPorts() {
      if (isBondingEnabled) {
        if (appliancePorts.length === 1 && portIndex === 0) {
          // User enabled bonding, but only one logical port - automatically add a logical port (two ports are required for bonding)
          onAddLogicalPortRequested()
        }
      } else {
        if (portIndex > 0) {
          // Cannot have multiple ports with bonding disabled
          onRemoveLogicalPortRequested()
        }
      }
    }

    ensureCorrectNumberLogicalPorts()
  }, [
    isBondingEnabled,
    previousIsBondingEnabled,
    appliancePorts,
    onAddLogicalPortRequested,
    portIndex,
    onRemoveLogicalPortRequested,
  ])

  const allLogicalPorts = collectPortsFromApplianceSections(getValues())
  const portsGroupedByAppliance = group(allLogicalPorts, (p) => getPortAppliance(p)?.id ?? '')
  const applianceSupportsRtpOverSrt = !applianceVersion || supportsRtpOverSrt(applianceVersion)
  const isRtpOverSrtSupported =
    applianceSupportsRtpOverSrt &&
    Object.values(portsGroupedByAppliance).every(
      // Max 1 SRT interface per appliance
      (appliancePorts) => appliancePorts.filter((p) => p.mode === IpPortMode.srt).length < 2,
    )

  // Only show RTP-over-SRT checkbox for first logical port per appliance
  const showRtpOverSrtCheckbox = portIndex === 0
  const isRtpOverSrtEnabled = allLogicalPorts.some((p) => p.mode === IpPortMode.srt && 'rtp' in p && !!p.rtp)

  const srtModeSupportsBonding = !!firstAppliancePort.srtMode && firstAppliancePort.srtMode !== SrtMode.rendezvous
  const isBondingSupported =
    !isRtpOverSrtEnabled &&
    srtModeSupportsBonding &&
    areMultipleLogicalPortsPerApplianceSupported({
      isInput: false,
      appliance: { id: applianceId, type: applianceType },
      allLogicalPorts,
    })

  useEffect(() => {
    if (portIndex !== 0) return
    if (isRtpOverSrtEnabled) {
      if (!isRtpOverSrtSupported) {
        setValue(`${portsNamePrefix}.0.${SrtFields.rtp}`, false)
      } else if (!currentPort.rtp) {
        setValue(`${portsNamePrefix}.0.${SrtFields.rtp}`, true)
      }
    }
  }, [portIndex, isRtpOverSrtEnabled, isRtpOverSrtSupported, setValue, currentPort.rtp])

  return (
    <>
      {showRtpOverSrtCheckbox && (
        <Checkbox
          label="RTP over SRT"
          name={`${namePrefix}.${SrtFields.rtp}`}
          disabled={!isRtpOverSrtSupported}
          tooltip={
            isRtpOverSrtSupported
              ? 'Enable this option if the SRT output should carry an RTP payload.'
              : !applianceSupportsRtpOverSrt
              ? 'Appliance software does not support RTP over SRT (requires R3.22.0 or greater)'
              : 'RTP-over-SRT cannot be enabled together with bonding'
          }
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.srtMode}`}
        label="Connection mode"
        newLine
        options={supportedModes}
        disabled={portIndex !== 0 && isBondingEnabled}
        required
      />
      {portIndex === 0 && (
        <Select
          name={`${namePrefix}.${SrtFields.bondingMode}`}
          label="Bonding mode"
          disabled={!isBondingSupported}
          options={srtBondingOptions(firstAppliancePort.srtMode)}
          tooltip={isRtpOverSrtEnabled ? 'Bonding is not supported when tunneling RTP over SRT' : undefined}
          required
        />
      )}
      {currentPort && [SrtMode.caller, SrtMode.rendezvous].includes(currentPort.srtMode) && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.remoteIp}`}
            label="Remote host"
            newLine
            required
            validators={{ ipOrHostname: {} }}
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.remotePort}`}
            label={currentPort?.srtMode === SrtMode.rendezvous ? 'Local and remote port' : 'Remote port'}
            required
            type="number"
            noNegative
            validators={{
              port: { isUdp: true },
              isPortAvailable: {
                ...currentPortInfo,
                existingLogicalPortsOnSameNic: currentPort.srtMode === SrtMode.rendezvous ? occupiedPorts : [],
              },
            }}
          />
        </>
      )}
      {currentPort.srtMode && [SrtMode.listener, SrtMode.rendezvous].includes(currentPort.srtMode) && (
        <Select
          name={localAddressSelector}
          label="Local address"
          required
          options={makeAddressOptions(getValues(localAddressSelector), addresses)}
          newLine
          validators={{
            addressIn: { addresses },
          }}
        />
      )}
      {[SrtMode.listener, SrtMode.caller].includes(currentPort?.srtMode) && (
        <SuggestedLocalPortTextField
          name={localPortSelector}
          label={currentPort.srtMode === SrtMode.listener ? 'Local port' : 'Local outgoing port'}
          required={currentPort.srtMode === SrtMode.listener}
          namePrefix={namePrefix}
          portInfo={currentPortInfo}
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.rateLimiting}`}
        label="Output rate limiting"
        options={Object.values(SrtRateLimiting)}
        required
        newLine
      />
      {currentPort.rateLimiting === SrtRateLimiting.absolute && (
        <TextInput
          name={`${namePrefix}.${SrtFields.maxBw}`}
          label="Maximum bandwidth (Mbps)"
          type="number"
          required
          noNegative
        />
      )}
      {currentPort.rateLimiting === SrtRateLimiting.relativeToInput && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.inputBw}`}
            label="Input bandwidth (Mbps)"
            required
            tooltip="Set to '0' for automatic input bandwidth sensing"
            type="number"
            noNegative
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.oheadBw}`}
            label="Overhead bandwidth (%)"
            tooltip="The allowed overhead of output bandwidth in relation to input bandwidth. Minimum 5%"
            required
            type="number"
            noNegative
            validators={{
              number: {
                greaterThanOrEqualTo: 5,
                lessThanOrEqualTo: 100,
                message: 'Must be 5 - 100',
              },
            }}
          />
        </>
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.latency}`}
        label="Retransmission buffer (ms)"
        type="number"
        noNegative
        required
        newLine
        tooltip="Contains packets that may need to be retransmitted. Shall be at least the same size as the retransmission buffer on the receiver."
        validators={{
          number: {
            lessThanOrEqualTo: LATENCY_MAX,
            message: `Must be 0 - ${LATENCY_MAX}`,
          },
        }}
      />
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.ipttl}`}
          label="TTL"
          type="number"
          noNegative
          tooltip='Defines IP socket "time to live" option.'
          validators={{
            number: {
              lessThanOrEqualTo: TTL_MAX,
              message: `Must be no more than ${TTL_MAX}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.mss}`}
          label="MTU"
          type="number"
          noNegative
          newLine
          tooltip="MTU size"
          validators={{
            number: {
              greaterThanOrEqualTo: MTU_MIN,
              message: `Must be more than ${MTU_MIN}`,
            },
          }}
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.pbkeylen}`}
        label="Encryption type"
        options={Object.entries(SrtKeylen).map(([name, value]) => ({ name, value }))}
        required
        newLine
      />
      {!!currentPort.pbkeylen && currentPort.pbkeylen !== SrtKeylen.none && (
        <TextInput
          name={`${namePrefix}.${SrtFields.passphrase}`}
          label="Passphrase"
          tooltip="Same passphrase must be configured at SRT Input to decrypt the stream"
          validators={{
            noAmpersand: {},
            len: {
              minimum: 10,
              maximum: 79,
            },
          }}
        />
      )}
      {currentPort?.srtMode === SrtMode.caller && (
        <TextInput name={`${namePrefix}.${SrtFields.streamId}`} label="Stream Id" newLine tooltip="Stream ID" />
      )}

      {!isVaApplianceType(applianceType) &&
        currentPort.srtMode &&
        [SrtMode.listener, SrtMode.rendezvous].includes(currentPort.srtMode) && (
          <WhitelistCidrBlockAutoComplete
            name={`${namePrefix}.${SrtFields.whitelistCidrBlock}`}
            required={isCoreNode(applianceType)}
          />
        )}
    </>
  )
}

export default SrtForm
